/**
 * Create $userReady Promise and $userResolve Function on nuxt context.
 *
 * $userResolve: called with user data when login is performed.
 *  - e.g: $userResolve({ name: "jedi" })
 *
 * $userReady: resolves user data when $userResolve is called
 *  - e.g: $userReady.then((user) => {
 *    // do something with user
 *  })
 */

export default (_ctx, inject) => {
  let userResolve = null
  const userReady = new Promise((resolve) => {
    userResolve = resolve
  })

  // Resolve user data e.g. $userResolve({ name: 'jedi' })
  inject('userResolve', userResolve)
  // Make value resolved by $userResolve available via .then method
  inject('userReady', userReady)
}
