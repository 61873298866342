var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{class:[
    'header',
    {
      'header--static': _vm.isMobileFixedMockup,
      'header--blackfriday': _vm.$store.state.isBlackFriday,
      'header--christmas': _vm.$store.state.isChristmas,
    } ]},[_c('div',{staticClass:"container header__container"},[_c('div',{staticClass:"header__nav"},[_c('a',{staticClass:"header__item header__item--hamburger header__item--mobile",attrs:{"href":"#","rel":"nofollow","aria-label":"Menu"},on:{"click":function($event){$event.preventDefault();}}},[_c('header-icon-hamburger')],1),_vm._v(" "),_c('nuxt-link',{class:[
          'header__item',
          'header__item--logo',
          {
            'header__item--logo-sudden':
              _vm.$isBrStore && _vm.$store.state.isGocaseBirth,
          } ],attrs:{"to":"/","aria-label":"Home"}},[_c('header-logo')],1),_vm._v(" "),_c('div',{staticClass:"header__item header__item--desktop header__item--menu",class:{ 'header__item--blackfriday': _vm.$store.state.isBlackFriday }},[(_vm.showMenu)?_c('header-menu',{staticClass:"desktop-only"}):_vm._e()],1),_vm._v(" "),(!_vm.noContent)?_c('div',{staticClass:"header__item header__item--searchfield"},[_c('header-search',{attrs:{"active":_vm.mobileInputSearchOpened},on:{"close":_vm.closeMobileInputSearch}})],1):_vm._e(),_vm._v(" "),(_vm.noContent)?_c('div',{staticClass:"header__item-100-seal"},[_c('sprite-tag',{attrs:{"tag":"selo_100-seguro"}})],1):_vm._e(),_vm._v(" "),(!_vm.noContent)?[_c('a',{staticClass:"header__item header__item--icon",attrs:{"href":"#","rel":"nofollow","aria-label":"Search"},on:{"click":function($event){$event.preventDefault();return _vm.openMobileInputSearch.apply(null, arguments)}}},[_c('icon-search')],1),_vm._v(" "),(_vm.isLogged)?_c('nuxt-link',{staticClass:"header__item header__item--icon",attrs:{"to":"/wishlist","aria-label":"Wishlist"}},[_c('icon-heart')],1):_vm._e(),_vm._v(" "),_c('nuxt-link',{staticClass:"header__item header__item--icon",attrs:{"to":"/cart","aria-label":"Cart"}},[_c('header-cart',{attrs:{"qty":_vm.cartQty}})],1),_vm._v(" "),_c('user-widget',{staticClass:"header__item header__item--icon"})]:_vm._e()],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }