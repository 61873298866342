import { render, staticRenderFns } from "./VScrollable.vue?vue&type=template&id=17018461&scoped=true&"
import script from "./VScrollable.vue?vue&type=script&lang=js&"
export * from "./VScrollable.vue?vue&type=script&lang=js&"
import style0 from "./VScrollable.vue?vue&type=style&index=0&id=17018461&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "17018461",
  null
  
)

export default component.exports