/**
 * Deep copy given object
 *
 * @param {Object} obj object to copy
 * @returns {Object} copied object
 */
export default function copyObject(obj) {
  if (typeof obj === 'object' && obj !== null) {
    try {
      return JSON.parse(JSON.stringify(obj))
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err)

      // shallow copy
      return Object.assign({}, obj)
    }
  }

  return obj
}
