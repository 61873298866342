<template>
  <header-menu-content
    v-if="headerMenu && headerMenu.length"
    :items="headerMenu"
  />
</template>

<script>
import { mapState } from 'vuex'
import HeaderMenuContent from './header-menu-content/HeaderMenuContent.vue'
import positionable from '~/mixins/positionable'

export default {
  name: 'HeaderMenu',
  components: {
    HeaderMenuContent,
  },
  mixins: [positionable],
  computed: {
    ...mapState(['siteStore']),
    headerMenu() {
      // eslint-disable-next-line camelcase
      const substoreMenus = this.siteStore?.substores_menu
      const menu = substoreMenus?.brasil?.length
        ? substoreMenus?.brasil
        : substoreMenus?.us

      if (this.$store.state.isUsStore && menu) {
        // eslint-disable-next-line camelcase
        return this.positioning(menu)
      }
      // eslint-disable-next-line camelcase
      return this.positioning(this.siteStore?.header_menu)
    },
  },
}
</script>
