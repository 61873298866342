/* eslint-disable eqeqeq */
/* eslint-disable one-var */
/* eslint-disable no-var */
/* eslint-disable prettier/prettier */
import pushDataLayer from '~/mixins/gtm/push-data-layer'
import { STORE_CREDENTIALS, UNIVERSAL_GTM  } from '~/config'
// import Cookie from '~/utils/Cookie'

export default ({ app, $userReady, $onUserConsent, $isBrStore }) => {
  const store = app.$storeCode
  const universalGtm = UNIVERSAL_GTM
  const id = universalGtm.enabled ? universalGtm.id : STORE_CREDENTIALS[store]?.gtm?.id

  const initGtm = () => {
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer', id);
    // eslint-disable-next-line no-console
    console.log('initing gtm.js', `| At time: ${performance.now()}`)
  }

  const initJuo = () => {
    (function(j,u,o){var g=j.getElementsByTagName(u)[0],s=j.createElement(u);s.async=true;s.src='https://cdn.juo.io/c/'+o+'.js';g.parentNode.insertBefore(s,g);})(document,'script','iHQ6idRpea')
  }

  const initOptimizeNormalizer = () => {
    (function(a,s,y,n,c,h,i,d,e){s.className+=' '+y;h.start=1*new Date;
  h.end=i=function(){s.className=s.className.replace(RegExp(' ?'+y),'')};
  (a[n]=a[n]||[]).hide=h;setTimeout(function(){i();h.end=null},c);h.timeout=c;
  })(window,document.documentElement,'async-hide','dataLayer',4000,
  {[id]:true});
  }

  initOptimizeNormalizer()
      
  // $onUserConsent.then(({ consented, reason }) => {
  //   const cookieData = {
  //     v4_consent: "consented",
  //     domain: ".gocase.com",
  //     path: "/",
  //     SameSite: "None",
  //     Secure: true
  //   }
  //   if (consented) {
  //     // eslint-disable-next-line no-console
  //     console.log('[cookiebot] User CONSENTED cookies. Reason:', reason, `| At time: ${performance.now()}`);

  //     const myDate = new Date()
  //     myDate.setMonth(myDate.getMonth() + 12)
  //     Cookie.Client(document.cookie).set({...cookieData, expires: myDate})
  //     // eslint-disable-next-line no-console
  //     console.log('[cookiebot] V4 cookie created.');

      // initGtm()
      // initJuo()
  //   } else {
  //     // eslint-disable-next-line no-console
  //     console.log('[cookiebot] User DECLINED cookies. GTM not initialized. Reason:', reason);

  //     const myDate = new Date()
  //     myDate.setMonth(myDate.getMonth() + 12)
  //     Cookie.Client(document.cookie).set({...cookieData, expires: myDate})

  //     // eslint-disable-next-line no-console
  //     console.log('[cookiebot] V4 cookie destroyed.');
  //   }
  // })
  // window.addEventListener('load', initGtm)

  app.router.afterEach((to, _from) => {
    const value = app.store.getters.sessionInfo
    value.isHomePage = (to.path === '/')
    value.path = to.path

    const event = {
      event: 'genericEvent',
      eventCategory: 'navigation',
      eventAction: 'pageView',
      eventValue: value,
    }

    pushDataLayer.methods.pushDataLayer(event)
    tiggerOnUserInteraction(event)
  })

  /**
   * Track user interaction event and send to GTM. Trigger once.
   */
  function tiggerOnUserInteraction(eventData) {
    const events = ['scroll', 'click', 'keyup', 'touchstart']

    events.forEach(event => {
      document.addEventListener(event, onIneraction, { once: true })
    })

    function onIneraction(e) {
      initGtm()
      initJuo()
      
      const eventLabel = [
        eventData?.event || "{event}",
        eventData?.eventCategory || "{category}",
        eventData?.eventAction || "{action}",
        eventData?.eventLabel || "{label}"
      ].join(' - ')

      pushDataLayer.methods.pushDataLayer({
        event: 'genericEvent',
        eventCategory: 'userInteraction',
        eventAction: e.type,
        eventLabel,
        eventStore: eventData?.eventStore, 
        eventValue: eventData?.eventValue,
      });

      removeListeners()
    }

    function removeListeners() {
      events.forEach(event => {
        document.removeEventListener(event, onIneraction)
      })
    }
  }
}
