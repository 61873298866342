import { GrowthBook } from '@growthbook/growthbook'

const getFeaturesJson = (featuresEndpoint) => {
  return fetch(featuresEndpoint).then((response) => {
    return response.json()
  })
}

export const GrowthBookVuePlugin = {
  install(Vue, { featuresEndpoint, enableDevMode = false }) {
    let growthBook = null

    Vue.prototype.initGrowthBook = function initGrowthBook() {
      if (growthBook) {
        return Promise.resolve(growthBook)
      }

      return getFeaturesJson(featuresEndpoint)
        .then((json) => {
          growthBook = new GrowthBook({
            enableDevMode,
          })

          growthBook.setFeatures(json.features)

          return growthBook
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error('GrowthBook Vue plugin initialization error', error)
          return null
        })
    }
  },
}
