if (process.client) {
  window.dataLayer = window.dataLayer || []
  // Fix: unecessary many session per user on Google Analytics
  // https://www.simoahava.com/gtm-tips/fix-rogue-referral-problem-single-page-sites/
  window.dataLayer.push({
    originalLocation:
      document.location.protocol +
      '//' +
      document.location.hostname +
      document.location.pathname +
      document.location.search,
  })
}

export default {
  methods: {
    pushDataLayer({
      event,
      eventCategory,
      eventAction,
      eventLabel,
      eventValue,
      ga4EventValue,
      insiderEventValue,
    }) {
      if (!process.client) {
        process.env.NODE_ENV === 'development' &&
          // eslint-disable-next-line no-console
          console.warn(
            'Trying to push to dataLayer on server-side. Please use this only on client side.'
          )
        return
      }
      const store = this.$store?.$storeCode || eventValue?.store

      const user = this.$store?.state?.user

      window.dataLayer.push({
        event,
        eventCategory,
        eventAction,
        eventLabel,
        eventValue,
        eventStore: store,
        ga4EventValue,
        insiderEventValue,
        userEventValue: user,
      })
    },
  },
}
