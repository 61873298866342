import { mapState } from 'vuex'
import adaptUrl from '~/commun/utils/adaptUrl'
import positionable from '~/mixins/positionable'

export default {
  mixins: [positionable],
  methods: {
    adaptUrl,
    name(item = this.item) {
      return item.name
    },
    nameSlug(item = this.item) {
      return item.name_slug
    },
    hasChild(item = this.item) {
      return item.children?.length > 0
    },
    childrenItems(item = this.item) {
      return this.positioning(item.children)
    },
    nextLevel(level = this.level) {
      return (level || 1) + 1
    },
    adaptedUrl(item = this.item) {
      return this.adaptUrl(item.url)
    },
  },
  computed: {
    ...mapState(['locale']),
  },
}
