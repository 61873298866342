export default function (
  { $axios, $sentry, $sentryReady, $storeApiUrl, $config: { spreeToken } },
  inject
) {
  const prefix = '/api/v2'

  $axios.setHeader('X-Spree-Token', spreeToken)
  $axios.defaults.withCredentials = true
  // Add params to purge cache on cloudflare
  $axios.defaults.params = { jd: '42' }

  // Settings Api Url by store
  $axios.defaults.baseURL = $storeApiUrl
  $axios.defaults.browserBaseURL = $storeApiUrl

  // Append api prefix
  $axios.interceptors.request.use((config) => {
    if (
      typeof config.baseURL === 'string' &&
      !config.baseURL.endsWith(prefix)
    ) {
      config.baseURL = config.baseURL.replace(/(\/+)$/, '') + prefix
    }

    if (
      typeof config.browserBaseURL === 'string' &&
      !config.browserBaseURL.endsWith(prefix)
    ) {
      config.browserBaseURL =
        config.browserBaseURL.replace(/(\/+)$/, '') + prefix
    }

    return config
  })

  // Timeout configs
  $axios.defaults.timeout = 20 * 1000 // in milliseconds

  // handle errors
  $axios.onError((error) => {
    // Skip Redirects and Not Found errors
    if ([301, 307, 308, 404, 400, 422].includes(error?.response?.status)) {
      return Promise.reject(error)
    }

    if (typeof $sentryReady === 'function') {
      $sentryReady().then((sentry) => sentry.captureException(error))
    } else if ($sentry) {
      $sentry.captureException(error)
    } else {
      // eslint-disable-next-line no-console
      console.error(
        '[Axios] Could not capture error. $sentryReady neither $sentry is defined on nuxt context.'
      )
    }

    return Promise.reject(error)
  })

  inject('apiv2', $axios)
}
