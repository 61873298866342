/* eslint-disable camelcase */
import Cookie from '~/utils/Cookie'

import {
  LOCALE_COOKIE_NAME,
  CURRENCY_COOKIE_NAME,
  STORES,
  CURRENCIES,
  LOCALES,
  GLOBAL_DEVICE_IS_ACTIVE,
} from '~/config'

export function initialStoreConfigs(req, query) {
  const currentHost = req ? req.headers.host : window.location.host

  const isBrStore = !!currentHost.match('.com.br')
  const isGlobalStore = !isBrStore
  let globalDevice, cartModal

  let cookie
  if (req) {
    cookie = req.headers.cookie
  } else {
    cookie = document.cookie
    globalDevice = localStorage.getItem('global_device')
    cartModal = sessionStorage.getItem('cart_modal')
  }

  const storeCode = isBrStore ? 'br' : 'global'
  const defaultStore = STORES.find((st) => st.code === storeCode)

  const cookieLocale = Cookie.Server(cookie).get(LOCALE_COOKIE_NAME)
  const locale = isBrStore
    ? defaultStore.locale
    : (LOCALES.includes(query?.language) ? query?.language : cookieLocale) ||
      defaultStore.locale

  const cookieCurrency = Cookie.Server(cookie).get(CURRENCY_COOKIE_NAME)
  const currency = isBrStore
    ? defaultStore.currency
    : (CURRENCIES.includes(query?.currency)
        ? query?.currency
        : cookieCurrency) || defaultStore.currency

  return {
    isBrStore,
    isGlobalStore,
    locale,
    currency,
    storeCode,
    lastCurrency: cookieCurrency,
    currencyFromUrl: !!query?.currency, // return boolean
    localeFromUrl: !!query?.language, // return boolean
    substoreFromUrl: query?.substore?.toUpperCase(), // return string
    localeFromCookie: !!cookieLocale,
    globalDeviceIsActive:
      (GLOBAL_DEVICE_IS_ACTIVE || !!query?.global_device || !!globalDevice) &&
      isBrStore,
    cartModalIsActive: true || !!query?.cart_modal || !!cartModal || isBrStore,
  }
}

export default function (
  {
    $config: {
      baseURL,
      baseBrowserURL,
      baseGlobalURL,
      baseGlobalBrowserURL,
      checkoutURL,
      checkoutGlobalURL,
    },
    query,
    req,
  },
  inject
) {
  const storeConfigs = initialStoreConfigs(req, query)

  const apiURL = process.server ? baseURL : baseBrowserURL
  const apiGlobalURL = process.server ? baseGlobalURL : baseGlobalBrowserURL

  const storeApiUrl = storeConfigs.isBrStore ? apiURL : apiGlobalURL
  const storeCheckoutUrl = storeConfigs.isBrStore
    ? checkoutURL
    : checkoutGlobalURL

  inject('isBrStore', storeConfigs.isBrStore)
  inject('isGlobalStore', storeConfigs.isGlobalStore)
  inject('storeApiUrl', storeApiUrl)
  inject('storeCheckoutUrl', storeCheckoutUrl)
  inject('currencyFromUrl', storeConfigs.currencyFromUrl)
  inject('localeFromUrl', storeConfigs.localeFromUrl)
  inject('localeFromCookie', storeConfigs.localeFromCookie)
  inject('globalDeviceIsActive', storeConfigs.globalDeviceIsActive)
  inject('cartModalIsActive', storeConfigs.cartModalIsActive)

  if (storeConfigs.substoreFromUrl) {
    inject('substoreFromUrl', storeConfigs.substoreFromUrl)
  }

  inject('storeCode', storeConfigs.storeCode)
  inject('locale', storeConfigs.locale)
  inject('currency', storeConfigs.currency)

  if (storeConfigs.lastCurrency) {
    inject('lastCurrency', storeConfigs.lastCurrency)
  }

  return {
    $isBrStore: storeConfigs.isBrStore,
    $isGlobalStore: storeConfigs.isGlobalStore,
    $storeApiUrl: storeApiUrl,
    $storeCheckoutUrl: storeCheckoutUrl,
    $locale: storeConfigs.locale,
    $currency: storeConfigs.currency,
    $lastCurrency: storeConfigs.lastCurrency,
    $storeCode: storeConfigs.storeCode,
    $currencyFromUrl: storeConfigs.currencyFromUrl,
    $localeFromUrl: storeConfigs.localeFromUrl,
    $substoreFromUrl: storeConfigs.substoreFromUrl,
    $localeFromCookie: storeConfigs.localeFromCookie,
    $globalDeviceIsActive: storeConfigs.globalDeviceIsActive,
    $cartModalIsActive: storeConfigs.cartModalIsActive,
  }
}
