import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _0a01a592 = () => interopDefault(import('../pages/category-filters/_slug.vue' /* webpackChunkName: "pages/category-filters/_slug" */))
const _5006fe8a = () => interopDefault(import('../pages/contact-us.vue' /* webpackChunkName: "pages/contact-us" */))
const _11e2a9f0 = () => interopDefault(import('../pages/collections/_slug.vue' /* webpackChunkName: "pages/collections/_slug" */))
const _523c031c = () => interopDefault(import('../pages/admin/index.vue' /* webpackChunkName: "pages/admin" */))
const _079375d0 = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _2209c705 = () => interopDefault(import('../pages/addresses/index.vue' /* webpackChunkName: "pages/addresses/index" */))
const _fbf8531a = () => interopDefault(import('../pages/cart/index.vue' /* webpackChunkName: "pages/cart/index" */))
const _c27d7b76 = () => interopDefault(import('../pages/central-de-ajuda.vue' /* webpackChunkName: "pages/central-de-ajuda" */))
const _3e44f320 = () => interopDefault(import('../pages/complete-registration/index.vue' /* webpackChunkName: "pages/complete-registration/index" */))
const _7032b6b2 = () => interopDefault(import('../pages/finalized-order.vue' /* webpackChunkName: "pages/finalized-order" */))
const _69f35b0d = () => interopDefault(import('../pages/find-order/index.vue' /* webpackChunkName: "pages/find-order/index" */))
const _0a8792e9 = () => interopDefault(import('../pages/go-for-good/index.vue' /* webpackChunkName: "pages/go-for-good/index" */))
const _371f504e = () => interopDefault(import('../pages/iphone15.vue' /* webpackChunkName: "pages/iphone15" */))
const _3703214c = () => interopDefault(import('../pages/iphone16.vue' /* webpackChunkName: "pages/iphone16" */))
const _7f88ef70 = () => interopDefault(import('../pages/kits/index.vue' /* webpackChunkName: "pages/kits/index" */))
const _cbb888a0 = () => interopDefault(import('../pages/landing-gifts/index.vue' /* webpackChunkName: "pages/landing-gifts/index" */))
const _57062649 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _44e50f8e = () => interopDefault(import('../pages/onde-encontrar/index.vue' /* webpackChunkName: "pages/onde-encontrar/index" */))
const _75114410 = () => interopDefault(import('../pages/orders/index.vue' /* webpackChunkName: "pages/orders/index" */))
const _09f51cfe = () => interopDefault(import('../pages/protocols/index.vue' /* webpackChunkName: "pages/protocols/index" */))
const _3b8e652b = () => interopDefault(import('../pages/redirect-by-device.vue' /* webpackChunkName: "pages/redirect-by-device" */))
const _031b5f76 = () => interopDefault(import('../pages/review-request-high-rating.vue' /* webpackChunkName: "pages/review-request-high-rating" */))
const _1e75200a = () => interopDefault(import('../pages/reviews.vue' /* webpackChunkName: "pages/reviews" */))
const _23a3fa07 = () => interopDefault(import('../pages/rewards/index.vue' /* webpackChunkName: "pages/rewards/index" */))
const _7d7e750b = () => interopDefault(import('../pages/samsungs24.vue' /* webpackChunkName: "pages/samsungs24" */))
const _596c08fb = () => interopDefault(import('../pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _57e1d548 = () => interopDefault(import('../pages/shopinsta/index.vue' /* webpackChunkName: "pages/shopinsta/index" */))
const _f8b8ef2a = () => interopDefault(import('../pages/signup/index.vue' /* webpackChunkName: "pages/signup/index" */))
const _fbea1628 = () => interopDefault(import('../pages/totem-congratulations/index.vue' /* webpackChunkName: "pages/totem-congratulations/index" */))
const _5786ead8 = () => interopDefault(import('../pages/wishlist/index.vue' /* webpackChunkName: "pages/wishlist/index" */))
const _d68ca602 = () => interopDefault(import('../pages/account/edit/index.vue' /* webpackChunkName: "pages/account/edit/index" */))
const _48cde7d6 = () => interopDefault(import('../pages/addresses/new/index.vue' /* webpackChunkName: "pages/addresses/new/index" */))
const _1273eff5 = () => interopDefault(import('../pages/update-order-items/editable-line-item/EditableLineItem.vue' /* webpackChunkName: "pages/update-order-items/editable-line-item/EditableLineItem" */))
const _0cc5c21a = () => interopDefault(import('../pages/update-order-items/editable-line-item/editable-line-item-form/EditableLineItemForm.vue' /* webpackChunkName: "pages/update-order-items/editable-line-item/editable-line-item-form/EditableLineItemForm" */))
const _7159ba6a = () => interopDefault(import('../pages/user/spree_user/password/edit/index.vue' /* webpackChunkName: "pages/user/spree_user/password/edit/index" */))
const _45b7d9d5 = () => interopDefault(import('../pages/user/spree_user/password/new/index.vue' /* webpackChunkName: "pages/user/spree_user/password/new/index" */))
const _71f5b64e = () => interopDefault(import('../pages/c/_uuid.vue' /* webpackChunkName: "pages/c/_uuid" */))
const _55ea640a = () => interopDefault(import('../pages/capinhas-para-celular/_slug.vue' /* webpackChunkName: "pages/capinhas-para-celular/_slug" */))
const _d89ca9a8 = () => interopDefault(import('../pages/kits/_kit/index.vue' /* webpackChunkName: "pages/kits/_kit/index" */))
const _16012b11 = () => interopDefault(import('../pages/orders/_number/index.vue' /* webpackChunkName: "pages/orders/_number/index" */))
const _15ea8417 = () => interopDefault(import('../pages/protocols/_number/index.vue' /* webpackChunkName: "pages/protocols/_number/index" */))
const _c65d95f4 = () => interopDefault(import('../pages/smartphone-cases/_slug.vue' /* webpackChunkName: "pages/smartphone-cases/_slug" */))
const _1b337cb4 = () => interopDefault(import('../pages/update-order-items/_number.vue' /* webpackChunkName: "pages/update-order-items/_number" */))
const _1e0d99d4 = () => interopDefault(import('../pages/addresses/_address_id/edit/index.vue' /* webpackChunkName: "pages/addresses/_address_id/edit/index" */))
const _3203d815 = () => interopDefault(import('../pages/orders/_number/tracking/_track_number.vue' /* webpackChunkName: "pages/orders/_number/tracking/_track_number" */))
const _bf4a0638 = () => interopDefault(import('../pages/t/_.vue' /* webpackChunkName: "pages/t/_" */))
const _0162acba = () => interopDefault(import('../pages/_slug/p/_case_device.vue' /* webpackChunkName: "pages/_slug/p/_case_device" */))
const _4002703f = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/encontre-sua-case/:slug",
    component: _0a01a592,
    name: "encontre-sua-case"
  }, {
    path: "/find-your-case/:slug",
    component: _0a01a592,
    name: "find-your-case"
  }, {
    path: "/fale-conosco",
    component: _5006fe8a,
    name: "fale-conosco"
  }, {
    path: "/colecoes/:slug?",
    component: _11e2a9f0,
    name: "colecoes-slug"
  }, {
    path: "/admin/*",
    component: _523c031c,
    name: "admin-all"
  }, {
    path: "/account",
    component: _079375d0,
    name: "account"
  }, {
    path: "/addresses",
    component: _2209c705,
    name: "addresses"
  }, {
    path: "/admin",
    component: _523c031c,
    name: "admin"
  }, {
    path: "/cart",
    component: _fbf8531a,
    name: "cart"
  }, {
    path: "/central-de-ajuda",
    component: _c27d7b76,
    name: "central-de-ajuda"
  }, {
    path: "/complete-registration",
    component: _3e44f320,
    name: "complete-registration"
  }, {
    path: "/contact-us",
    component: _5006fe8a,
    name: "contact-us"
  }, {
    path: "/finalized-order",
    component: _7032b6b2,
    name: "finalized-order"
  }, {
    path: "/find-order",
    component: _69f35b0d,
    name: "find-order"
  }, {
    path: "/go-for-good",
    component: _0a8792e9,
    name: "go-for-good"
  }, {
    path: "/iphone15",
    component: _371f504e,
    name: "iphone15"
  }, {
    path: "/iphone16",
    component: _3703214c,
    name: "iphone16"
  }, {
    path: "/kits",
    component: _7f88ef70,
    name: "kits"
  }, {
    path: "/landing-gifts",
    component: _cbb888a0,
    name: "landing-gifts"
  }, {
    path: "/login",
    component: _57062649,
    name: "login"
  }, {
    path: "/onde-encontrar",
    component: _44e50f8e,
    name: "onde-encontrar"
  }, {
    path: "/orders",
    component: _75114410,
    name: "orders"
  }, {
    path: "/protocols",
    component: _09f51cfe,
    name: "protocols"
  }, {
    path: "/redirect-by-device",
    component: _3b8e652b,
    name: "redirect-by-device"
  }, {
    path: "/review-request-high-rating",
    component: _031b5f76,
    name: "review-request-high-rating"
  }, {
    path: "/reviews",
    component: _1e75200a,
    name: "reviews"
  }, {
    path: "/rewards",
    component: _23a3fa07,
    name: "rewards"
  }, {
    path: "/samsungs24",
    component: _7d7e750b,
    name: "samsungs24"
  }, {
    path: "/search",
    component: _596c08fb,
    name: "search"
  }, {
    path: "/shopinsta",
    component: _57e1d548,
    name: "shopinsta"
  }, {
    path: "/signup",
    component: _f8b8ef2a,
    name: "signup"
  }, {
    path: "/totem-congratulations",
    component: _fbea1628,
    name: "totem-congratulations"
  }, {
    path: "/wishlist",
    component: _5786ead8,
    name: "wishlist"
  }, {
    path: "/account/edit",
    component: _d68ca602,
    name: "account-edit"
  }, {
    path: "/addresses/new",
    component: _48cde7d6,
    name: "addresses-new"
  }, {
    path: "/update-order-items/editable-line-item/EditableLineItem",
    component: _1273eff5,
    name: "update-order-items-editable-line-item-EditableLineItem"
  }, {
    path: "/update-order-items/editable-line-item/editable-line-item-form/EditableLineItemForm",
    component: _0cc5c21a,
    name: "update-order-items-editable-line-item-editable-line-item-form-EditableLineItemForm"
  }, {
    path: "/user/spree_user/password/edit",
    component: _7159ba6a,
    name: "user-spree_user-password-edit"
  }, {
    path: "/user/spree_user/password/new",
    component: _45b7d9d5,
    name: "user-spree_user-password-new"
  }, {
    path: "/c/:uuid?",
    component: _71f5b64e,
    name: "c-uuid"
  }, {
    path: "/capinhas-para-celular/:slug?",
    component: _55ea640a,
    name: "capinhas-para-celular-slug"
  }, {
    path: "/category-filters/:slug?",
    component: _0a01a592,
    name: "category-filters-slug"
  }, {
    path: "/collections/:slug?",
    component: _11e2a9f0,
    name: "collections-slug"
  }, {
    path: "/kits/:kit",
    component: _d89ca9a8,
    name: "kits-kit"
  }, {
    path: "/orders/:number",
    component: _16012b11,
    name: "orders-number"
  }, {
    path: "/protocols/:number",
    component: _15ea8417,
    name: "protocols-number"
  }, {
    path: "/smartphone-cases/:slug?",
    component: _c65d95f4,
    name: "smartphone-cases-slug"
  }, {
    path: "/update-order-items/:number?",
    component: _1b337cb4,
    name: "update-order-items-number"
  }, {
    path: "/addresses/:address_id/edit",
    component: _1e0d99d4,
    name: "addresses-address_id-edit"
  }, {
    path: "/orders/:number/tracking/:track_number?",
    component: _3203d815,
    name: "orders-number-tracking-track_number"
  }, {
    path: "/t/*",
    component: _bf4a0638,
    name: "t-all"
  }, {
    path: "/:slug?/p/:case_device?",
    component: _0162acba,
    name: "slug-p-case_device"
  }, {
    path: "/*",
    component: _4002703f,
    name: "all"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
