<template>
  <button
    :class="classStyle"
    :disabled="loading"
    :style="styles"
    v-on="$listeners"
  >
    <template v-if="loading">
      <div class="v-button--loading">
        {{ loadingText }}
        <loading />
      </div>
    </template>
    <template v-else>
      {{ label }}
      <!-- @slot button content -->
      <slot />
    </template>
  </button>
</template>

<script>
import Loading from '~/components/loadings/three-dots'
// import { i18n } from '~/plugins/i18n'

export default {
  name: 'VButton',
  components: { Loading },
  props: {
    label: {
      type: String,
      default: null,
    },
    block: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: 'primary',
    },
    /** whether state is loading or not */
    loading: {
      type: Boolean,
      default: false,
    },
    loadingText: {
      type: String,
      default: '',
    },
    /** whether use full width */
    fullWidth: {
      type: Boolean,
      default: false,
    },
    height: {
      type: [String, Number],
      default: '50px',
    },
    smallest: {
      type: Boolean,
      default: false,
    },
    discrete: {
      type: Boolean,
      default: false,
    },
    radius: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    classStyle() {
      const {
        block,
        color,
        fullWidth,
        loading,
        smallest,
        discrete,
        radius,
      } = this
      return [
        'v-button',
        color ? `v-button--${color}` : null,
        {
          'v-button--block': block,
          'v-button--full-width': fullWidth,
          'v-button--loading': loading,
          'v-button--smallest': smallest,
          'v-button--discrete': discrete,
          'v-button--radius': radius,
        },
      ]
    },
    styles() {
      if (this.smallest) return {}
      return {
        height: this.height,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
$color_error: lightcoral;

.v-button {
  padding: 12px 18px;
  background: #444;
  border: 0;
  color: #fff;
  font-size: inherit;
  text-transform: none;
  cursor: pointer;
  text-decoration: none;
  box-sizing: border-box;
  line-height: 22px;

  &:disabled {
    opacity: 0.6;
    cursor: default;
  }
}

.v-button--radius {
  border-radius: 3px;
}

.v-button--danger {
  background: $color_error;
  color: white;

  &:hover {
    background: darken($color_error, 10%);
  }
}

.v-button--block {
  display: block;
  width: 100%;
  text-align: center;
}

.v-button--smallest {
  height: 36px;
  line-height: 36px;
  padding: 0 10px;
  font-size: 11px;
}

.v-button--gray {
  background-color: #f2f2f6;
  color: #666;
  border-color: #f2f2f6;
}

.v-button--full-width {
  width: 100%;
}

.v-button--loading {
  display: flex;
  justify-content: center;
  align-items: center;
}

.v-button--discrete {
  background: transparent;
  border: 1px solid #d2d2d6;
  color: #666;

  &:hover {
    background: #e2e2e6;
  }
}

.v-button:hover {
  opacity: 0.9;
}
</style>
