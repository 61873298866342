import appendUrlParam from '~/utils/appendUrlParam'

export default {
  created() {
    if (process.client) {
      this.urlCouponApplier()
    }
  },
  methods: {
    urlCouponApplier() {
      // eslint-disable-next-line camelcase
      const couponCode = this.$route?.query?.coupon_code

      if (couponCode) {
        const params = {
          ...this.$store.getters.storeInfo,
          coupon_code: couponCode,
        }

        this.$axios
          .$post(appendUrlParam('coupons/apply', params))
          .then((result) => {
            if (this.$route.name === 'cart') {
              this.cart = result.cart
            }
            this.$displayTopMessage(result.message)
          })
          .catch((err) => {
            const errorMessage = err?.response?.data?.message
            this.$displayTopMessage(errorMessage, {
              type: 'error',
            })
          })
      }
    },
  },
}
