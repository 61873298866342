export default function URLQueryStringToObject(queryString) {
  if (typeof queryString !== 'string' || !queryString) {
    return {}
  }

  const obj = {}
  queryString.replace(/([^=&]+)=([^&]*)/g, function (m, key, value) {
    obj[decodeURIComponent(key)] = decodeURIComponent(value)
  })
  return obj
}
