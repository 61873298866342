/**
 * Cookie getters fot Client or Server
 *
 * @param {String} Server constructor: String with cookie
 * @param {String} Client constructor: String with cookie
 *
 * @param {String} get method: String with cookie name
 *
 * Example: Cookie.Server(cookie).get('v4_locale') or instead Server use Client(optional param) for client side
 *
 * @returns {String} requested cookie value
 */

const Cookie = {
  cookie: null,
  server: false,
  Server(cookie) {
    this.cookie = cookie
    this.server = true

    return {
      get: (name) => this._cookieGetter(name),
      set: (object) => this._cookieSetter(object),
    }
  },
  Client(cookie = document.cookie) {
    this.cookie = cookie
    return {
      get: (name) => this._cookieGetter(name),
      set: (object) => this._cookieSetter(object),
    }
  },
  IndependentContext(ctx) {
    this.cookie = ctx ? ctx?.req?.headers?.cookie : document?.cookie

    return {
      get: (name) => this._cookieGetter(name),
      set: (object) => this._cookieSetter(object),
    }
  },
  _cookieGetter(name) {
    if (this.cookie) {
      let cookieLocale = this.cookie
        .split(';')
        .find((c) => c.trim().startsWith(`${name}=`))
      if (cookieLocale) cookieLocale = cookieLocale.split('=')[1]
      return cookieLocale
    } else {
      return null
    }
  },
  _cookieSetter(object) {
    let cookie = ''

    Object.entries(object).forEach(([key, value]) => {
      if (['Secure', 'HttpOnly'].includes(key)) cookie += `${key};`
      else cookie += `${key}=${value};`
    })

    if (this.server) {
      return cookie
    } else {
      document.cookie = cookie
    }
  },
}
export default Cookie
