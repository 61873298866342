<template>
  <div class="icon-hamburger" @click="openDrawer">
    <span
      :class="[
        'icon-hamburger__lines',
        {
          'icon-hamburger__lines--blackfriday': $store.state.isBlackFriday,
          'icon-hamburger__lines--christmas': $store.state.isChristmas,
        },
      ]"
    />
  </div>
</template>

<script>
export default {
  name: 'HeaderIconHamburguer',
  methods: {
    openDrawer() {
      this.$store.dispatch('drawer/openDrawer')
    },
  },
}
</script>

<style lang="scss">
@media (min-width: $screen_large) {
  .icon-hamburger {
    display: none !important;
  }
}

.icon-hamburger {
  display: block;
  box-sizing: content-box;
  width: 22px;
  padding: 15px 15px 24px;
  margin-left: auto;
  margin-right: auto;
}

.icon-hamburger:hover {
  cursor: pointer;
  background-color: transparent;
}

.icon-hamburger__lines {
  position: relative;
  display: block;
  margin-top: 10px;
}

.icon-hamburger__lines,
.icon-hamburger__lines:before,
.icon-hamburger__lines:after {
  width: 100%;
  height: 1px;
  -webkit-transition: all 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  transition: all 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  background-color: #555;
}

.icon-hamburger__lines--blackfriday,
.icon-hamburger__lines--blackfriday:before,
.icon-hamburger__lines--blackfriday:after {
  background-color: white !important;
}

.icon-hamburger__lines--christmas,
.icon-hamburger__lines--christmas:before,
.icon-hamburger__lines--christmas:after {
  background-color: #ffffff !important;
}

.icon-hamburger__lines:before,
.icon-hamburger__lines:after {
  position: absolute;
  top: -6px;
  left: 0;
  content: ' ';
}

.icon-hamburger__lines:after {
  top: 6px;
}
</style>
