<template>
  <div id="overlaid-loading" class="overlay-loading">
    <logoAnimated></logoAnimated>
  </div>
</template>

<script>
import logoAnimated from '~/components/icons/logo-animated/LogoAnimated'
export default {
  components: {
    logoAnimated,
  },
}
</script>

<style lang="scss">
.overlay-loading {
  background: #fff;
  opacity: 0.9;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
