<template>
  <li
    :class="[
      item.css_class,
      item.name_slug,
      { 'nav-item--blackfriday': $store.state.isBlackFriday },
    ]"
  >
    <a
      :class="[{ sale: isLeftFeatured }]"
      :href="adaptedUrl()"
      @click.prevent="pointerEventsNone"
    >
      <featured-nav-item
        v-if="featured"
        :item="item"
        :is-left-featured="isLeftFeatured"
      />

      <span v-else>{{ name() }}</span>

      <icon-chevron-down
        v-if="hasChild() && level <= 1"
        class="main-menu__arrow main-menu__arrow--top"
      />
      <icon-chevron-right
        v-if="hasChild() && level > 1"
        class="main-menu__arrow"
      />
    </a>
    <ul
      v-show="hasChild()"
      :class="[
        `main-menu__level-${level}`,
        {
          'main-menu__level--blackfriday': $store.state.isBlackFriday,
          'main-menu__level--christmas': $store.state.isChristmas,
        },
      ]"
    >
      <header-menu-content-item
        v-for="(childItem, idx) in childrenItems()"
        :key="`${level}${idx}`"
        :item="childItem"
        :level="nextLevel()"
      />
    </ul>
  </li>
</template>

<script>
import menulable from '~/mixins/menulable'
import IconChevronDown from '~/components/icons/rounded-arrow-down/RoundedArrowDown'
import IconChevronRight from '~/components/icons/rounded-arrow-right/RoundedArrowRight'
import FeaturedNavItem from '~/components/header/featured-nav-item/FeaturedNavItem'

export default {
  name: 'HeaderMenuContentItem',
  components: {
    IconChevronDown,
    IconChevronRight,
    FeaturedNavItem,
  },
  mixins: [menulable],
  props: {
    item: {
      type: Object,
      required: true,
    },
    level: {
      type: Number,
      default: 1,
    },
    featured: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isLeftFeatured() {
      return [
        'ofertas-relampago',
        'sale',
        'offres',
        'ofertas-y-descuentos',
        'offerte',
        'rabatte',
        'ofertas',
        'saldi',
      ].includes(this.nameSlug())
    },
  },
  methods: {
    pointerEventsNone() {
      const url = this.adaptedUrl()
      if (url.match('https')) {
        window.open(url, '_blank')
      } else {
        window.open(url, '_self')
      }
      document.querySelector('.main-menu').style.pointerEvents = 'none'
      setTimeout(() => {
        document.querySelector('.main-menu').style.pointerEvents = 'auto'
      }, 500)
    },
  },
}
</script>

<style lang="scss" scoped>
li {
  a {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  span {
    margin-right: 5px;
  }
}
</style>
