export const state = () => ({
  selectedDeviceName: '',
  selectedDeviceBrand: '',
})

export const mutations = {
  setSelectedDevice(state, device) {
    state.selectedDeviceName = device.name
    state.selectedDeviceBrand = device.brand
  },
}
