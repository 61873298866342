/* eslint-disable no-console */
import Vue from 'vue'
import TopMessage from '~/components/TopMessage'

const TopMessageComponent = Vue.extend(TopMessage)

/**
 * Globay display a message at the top of the page
 *
 * @param {String} message message to display
 * @param {Object} options set TopMessage component props
 *
 * @returns component instance
 */

export function displayTopMessage(message, options = {}) {
  const containerId = 'top-message'
  let containerEl = document.getElementById(containerId)

  if (!containerEl) {
    containerEl = document.createElement('div')
    containerEl.setAttribute('id', containerId)
    document.body.appendChild(containerEl)
  }

  return new TopMessageComponent({
    propsData: {
      message,
      ...options,
    },
  }).$mount(containerEl)
}

Vue.prototype.$displayTopMessage = process.client
  ? displayTopMessage
  : console.log
