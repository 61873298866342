<template>
  <v-scrollable
    v-if="popularSearches.length"
    :label="$t('search.popular')"
    :items="popularSearches"
    :show-text="(term) => term"
    left="45px"
    @change="(item) => $emit('change') && $router.push(`/search?q=${item}`)"
  />
</template>

<script>
import VScrollable from '~/components/v-scrollable/VScrollable.vue'
export default {
  name: 'PopularSearch',
  components: {
    VScrollable,
  },
  computed: {
    popularSearches() {
      return this.$store.state.popularSearches?.popularSearches
    },
  },
}
</script>
