import VueLib from 'vue'

import srcset from '~/directives/srcset'
import lazyload from '~/directives/lazyload'
import intersect from '~/directives/intersect'
import v4Mask from '~/directives/v4-mask'

VueLib.directive('srcset', srcset)
VueLib.directive('lazyload', lazyload)
VueLib.directive('intersect', intersect)
VueLib.directive('v4Mask', v4Mask)
