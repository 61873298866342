/**
 * Returns a function, that, as long as it continues to be invoked,
 * will not be triggered before the wait milliseconds.
 *
 * @param {Function} func function to be called
 * @param {Number} wait time in milleseconds to run given func
 * @param {Boolean} immediate trigger the func on the leading edge instead of the trailing
 *
 * @returns {Function} debounced function
 */
export default function debounce(func, wait = 200, immediate) {
  let timeout
  return function () {
    const context = this
    const args = arguments
    const later = function () {
      timeout = null
      if (!immediate) func.apply(context, args)
    }
    const callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) func.apply(context, args)
  }
}
