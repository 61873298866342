let userAgent, splittedUserAgent, isGoogleBot

export default function filterGlobalDevice(headers) {
  if (!headers)
    throw new Error(
      'filterGlobalDevice is expecting receive headers string as param '
    )

  userAgent = headers['user-agent']
  splitUserAgent()
  googleBotChecker()

  return resultObject()
}

function resultObject() {
  const layers = allLayers()
  const improvedLayers = filterByLayer(layers)

  return Object.freeze({
    layers,
    improvedLayers,
    splittedUserAgent,
    userAgent,
    isGoogleBot,
  })
}

function filterByLayer(layers) {
  layers = improveLayersPerformance(layers)
  return layers
}

function improveLayersPerformance(layers) {
  return layers
    ?.map((layer) => {
      layer = layer?.replace(/(samsung)|(android)|(google)|(microsoft)/gi, '')
      return layer?.trim()
    })
    ?.filter((layer) => !!layer && !layer.match(/build/i))
    ?.filter(onlyUnique)
    ?.sort((a, b) => b.length - a.length)
}

function allLayers() {
  const layers = [
    firstLayer(),
    secondLayer(),
    thirdLayer(),
    fourthLayer(),
    fifthLayer(),
    sixtyLayer(),
  ].map((layer) => layer?.trim() || '')

  return Object.freeze(layers)
}

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index
}

function firstLayer() {
  // GREAT TO: |Samsung|
  return splittedUserAgent?.[2]?.trim()?.split(' ')[0]
}

function secondLayer() {
  // GREAT TO: |Google, Motorola|
  return splittedUserAgent?.[2]
}

function thirdLayer() {
  // GREAT TO: |Samsung Old, LG|
  return splittedUserAgent?.[4]?.trim()?.split(' ')[0]
}

function fourthLayer() {
  // GREAT TO: |Nokia, LG|
  return splittedUserAgent?.[splittedUserAgent?.length - 1]
}

function fifthLayer() {
  // GREAT TO: |Nokia, LG|
  return splittedUserAgent?.[splittedUserAgent?.length - 1]
    ?.trim()
    ?.split(' ')[0]
}

function sixtyLayer() {
  // GREAT TO: |Nexus|
  return splittedUserAgent?.[splittedUserAgent?.length - 1]
    ?.trim()
    ?.split('Build')[0]
}

function splitUserAgent() {
  splittedUserAgent = userAgent?.match(/\(([^)]+)\)/)?.[1]?.split(';')
}

function googleBotChecker() {
  isGoogleBot = !!userAgent?.match(/googlebot/i)
}
