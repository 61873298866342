export const state = () => ({
  gamification: null,
  error: false,
  loading: false,
})

export const mutations = {
  SET_GAMIFICATION(state, gamification) {
    state.gamification = gamification
  },
  SET_ERROR(state, error) {
    state.error = error
  },
  SET_LOADING(state, loading) {
    state.loading = loading
  },
}

export const actions = {
  async updateGamification({ commit }, { storeInfo, orderNumber }) {
    try {
      if (!orderNumber) return

      commit('SET_LOADING', true)

      const {
        data: { gamification },
      } = await this.$apiv2.get('cart_gamifications/pertinent', {
        params: {
          ...storeInfo,
          number: orderNumber,
        },
      })

      commit('SET_GAMIFICATION', gamification)
      commit('SET_ERROR', false)

      return gamification
    } catch (err) {
      commit('SET_ERROR', true)
      this.$sentry.captureException(err)
      return err
    } finally {
      commit('SET_LOADING', false)
    }
  },
}
