const intersectionConfig = {
  rootMargin: '10px 10px',
  threshold: 0.01,
}

// const fetchResource = (target) => {
//   const { src, srcset } = target.dataset
//   return new Promise(function (resolve, reject) {
//     const image = new Image()
//     image.onerror = reject
//     image.onload = resolve
//     if (srcset) {
//       image.srcset = srcset
//     } else if (src) image.src = src
//   })
// }

const preloadImage = (target) => {
  try {
    const { src, srcset, sizes } = target.dataset
    if (sizes) target.sizes = sizes
    // this is fetching the same image many times
    // await fetchResource(target)
    if (srcset && target.srcset !== srcset) {
      target.srcset = srcset
    } else if (src && target.src !== src) target.src = src
  } catch (err) {
    target.src =
      'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP89OnbfwAJkwPba4n1ewAAAABJRU5ErkJggg=='
  }
}

const handleIntersection = (entries, observer) => {
  entries.forEach((entry) => {
    if (entry.intersectionRatio > 0) {
      observer.unobserve(entry.target)
      preloadImage(entry.target)
    }
  })
}

const observer = new IntersectionObserver(
  handleIntersection,
  intersectionConfig
)

export default function (el, binding) {
  if (binding?.value?.ignore) return

  el._observer = observer
  observer.observe(el)
}
