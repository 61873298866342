import isPresent from '~/utils/isPresent'
/**
 * Converto an Object key-value pair to URL query string
 *
 * @param {Object} object values to contert to query params
 * @param {Object} options.removeBlankKeys remove keys with blank values e.g {a: ''}
 *
 * @returns {String} query string
 */
export default function queryString(object, options = {}) {
  if (!isPresent(object)) return ''

  const { removeBlankKeys } = options

  return Object.keys(object)
    .filter((key) => (removeBlankKeys ? isPresent(object[key]) : true))
    .map((key) => {
      return encodeURIComponent(key) + '=' + encodeURIComponent(object[key])
    })
    .join('&')
}
