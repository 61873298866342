/**
 *
 * @param {String} url URL to append params
 * @param {Object} params object contatins the params
 *
 * @returns {String} new URL with given params appended
 *
 * @example
 * cosnt url = 'http::/e.com'
 *
 * appendUrlParam(url, {a: 1})
 * // 'http::/e.com?a=1'
 */
export default function appendUrlParam(url, params = {}) {
  const p = Object.entries(params)
    .map((e) => e.join('='))
    .join('&')

  const elo = /\/?\?/.test(url) ? '&' : '?'

  return url + elo + p
}
