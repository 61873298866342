/**
 * Check if given value is blank
 * @param {*} value any value
 *
 * @returns {Boolean} true if value is blank
 */
export default function isBlank(value) {
  const PRIMITIVE_BLANK_VALUES = ['', null, undefined]

  const isEmptyObject =
    typeof value === 'object' &&
    value !== null &&
    Object.keys(value).length === 0

  const isEmptyArray = Array.isArray(value) && value.length === 0

  return PRIMITIVE_BLANK_VALUES.includes(value) || isEmptyObject || isEmptyArray
}
