export const state = () => ({
  devicesData: [],
})

export const mutations = {
  SET_DEVICE_DATA(state, devicesData) {
    state.devicesData = devicesData
  },
}

export const actions = {
  updateDeviceData({ commit }, devicesData) {
    commit('SET_DEVICE_DATA', devicesData)
  },
}

export const getters = {
  getDeviceData: (state) => state.devicesData,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
