<template>
  <logoAnimated :color="color"></logoAnimated>
</template>

<script>
import logoAnimated from '~/components/icons/logo-animated/LogoAnimated'
export default {
  components: {
    logoAnimated,
  },
  props: {
    color: {
      type: String,
      default: '#f37053',
    },
  },
}
</script>
