<template>
  <a
    :class="['logout-button', $attrs.class]"
    rel="nofollow"
    @click.prevent="logout"
  >
    <exit-icon v-if="icon" color="#555" size="15" />
    <template v-if="failed">
      <span class="logout-button--error">
        {{ $t('user.logout_error') }}
      </span>
      <span class="logout-button__retry" @click="logout">
        {{ $t('try_again') }}
      </span>
    </template>
    <template v-else>
      {{ isLogginOut ? $t('user.logging_out') : $t('user.logout') }}
    </template>
  </a>
</template>
<script>
import ExitIcon from '~/components/icons/exit/ExitIcon.vue'
export default {
  components: { ExitIcon },
  props: {
    icon: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLogginOut: false,
      failed: false,
    }
  },
  methods: {
    async logout() {
      this.startLogout()

      const logoutSuccess = await this.$store.dispatch('logout')

      if (logoutSuccess) window.location.replace('/')
      else this.failed = true
    },
    startLogout() {
      this.failed = false
      this.isLogginOut = true
    },
  },
}
</script>
