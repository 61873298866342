export const state = () => ({
  lastCategories: {},
  isLoading: false,
  smartRedirectPath: '',
})

export const mutations = {
  SET_CATEGORIES(state, categories) {
    state.lastCategories = categories
  },
  SET_LOADING(state, boolean) {
    state.isLoading = boolean
  },
  SET_SMART_REDIRECT_PATH(state, path) {
    state.smartRedirectPath = path
  },
}

export const actions = {
  updateLastCategories({ commit }, categories) {
    commit('SET_CATEGORIES', categories)
  },
  setLoading({ commit }, boolean) {
    commit('SET_LOADING', boolean)
  },
  updateSmartRedirectPath({ commit }, path) {
    commit('SET_SMART_REDIRECT_PATH', path)
  },
}

export const getters = {
  lastCategories({ lastCategories }) {
    return lastCategories
  },
  isLoading({ isLoading }) {
    return isLoading
  },
  getSmartRedirectPath({ smartRedirectPath }) {
    return smartRedirectPath
  },
}
