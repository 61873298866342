const MANY_SLASH_REGEXP = /\/{2,}/g

/**
 * Given vue-router Route, removes extra slashes
 *
 * @param {Object} route vue-router Route to sanitize path
 *
 * @returns new full path with extra slashes removed or null if theres no extra /
 */
export default function sanitizePath(route) {
  if (MANY_SLASH_REGEXP.test(route.path)) {
    const oldPath = route.path
    const newPath = route.path.replace(MANY_SLASH_REGEXP, '/')
    const newFullPath = route.fullPath.replace(oldPath, newPath)

    return newFullPath
  }

  return null
}
