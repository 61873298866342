<template>
  <div
    v-show="shouldDisplay"
    id="top-message"
    :class="['top-message', 'slide-in-top', { error }]"
  >
    {{ message }}
  </div>
</template>

<script>
import isPresent from '~/utils/isPresent'
export default {
  name: 'TopMessage',
  props: {
    /** The message to display */
    message: {
      type: String,
      default: '',
    },
    /** Hides the message after milliseconds */
    hidesAfter: {
      type: Number,
      default: 5000, // 5 seconds
    },
    /** Message type e.g. error */
    type: {
      type: String,
      default: 'success',
      validator: (v) => ['success', 'error'].includes(v),
    },
  },
  data() {
    return {
      show: true,
    }
  },
  computed: {
    error() {
      return this.type === 'error'
    },
    shouldDisplay() {
      return this.show && isPresent(this.message)
    },
  },
  mounted() {
    if (isPresent(this.message)) this.startCloseTimeout()
  },
  methods: {
    startCloseTimeout() {
      clearTimeout(this.timeoutId)

      this.timeoutId = setTimeout(this.hide, this.hidesAfter)
    },
    hide() {
      this.show = false
    },
  },
}
</script>
<style lang="scss" scoped>
.top-message {
  color: white;
  padding: 10px 15px;
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: mediumaquamarine;
  z-index: 999;

  &.error {
    background: $color_error;
  }
}

.slide-in-top {
  -webkit-animation: slide-in-top 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-top 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-100px);
    transform: translateY(-100px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-100px);
    transform: translateY(-100px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
</style>
