<template>
  <!-- eslint-disable vue/no-v-html -->
  <div
    v-if="messages.length"
    :class="[
      'topbar',
      {
        'topbar--cart': isCartPage,
        'topbar--gamification': gamification && gamification.gamification,
        'topbar--blackfriday': $store.state.isBlackFriday,
      },
    ]"
  >
    <div class="topbar__container container">
      <div class="topbar__messages">
        <div
          v-for="(message, idx) in messages"
          :key="idx"
          :class="[
            'topbar__message',
            {
              'topbar__message--gamification':
                gamification && gamification.gamification,
            },
          ]"
          v-html="message"
        ></div>
        <div class="topbar__progress">
          <promo-event-bar
            v-if="gamification.gamification"
            :progress="gamification.gamification.achieved"
          />
        </div>
      </div>

      <ul class="topbar__nav">
        <li v-if="$isBrStore">
          <a
            href="/go-for-good"
            class="topbar__nav-link"
            aria-label="Go For Good"
          >
            Go For Good
          </a>
        </li>
        <li v-if="$isBrStore">
          <a
            href="https://reseller.gocase.com.br/pt-BR/welcome"
            class="topbar__nav-link"
            target="_blank"
            rel="noopener"
            aria-label="Seja Revendedor(a)"
          >
            {{ $t('header.become_reseller') }}
          </a>
        </li>
        <li v-if="$isBrStore">
          <a
            href="https://corporativo.gocase.com.br/"
            class="topbar__nav-link"
            target="_blank"
            rel="noopener"
            aria-label="Brindes Corporativos"
          >
            {{ $t('header.corporate_gifts') }}
          </a>
        </li>
        <li>
          <a
            href="/find-order"
            class="topbar__nav-link"
            rel="noopener"
            aria-label="$isGlobalStore ? 'Track Order' : 'Rastrear Pedido'"
            @click="sendTrackOrderClickDesktopToGTM"
          >
            {{ $t('header.track_order') }}
          </a>
        </li>
        <li>
          <a
            :href="helpCenterUrl"
            class="topbar__nav-link"
            target="_blank"
            rel="noopener"
            aria-label="Help Center"
          >
            {{ $t('help_center') }}
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import PromoEventBar from '~/components/promo-event-bar/PromoEventBar'
import pushDataLayer from '~/mixins/gtm/push-data-layer'

export default {
  components: { PromoEventBar },
  mixins: [pushDataLayer],
  data() {
    return {
      helpCenterUrl: this.$isBrStore
        ? 'https://www.gocase.com.br/central-de-ajuda'
        : 'https://app.goconnect360.com.br/hc/help-center/en',
    }
  },
  watch: {
    '$store.state.userInteracted'() {
      this.startTopbarAnimation()
    },
  },
  computed: {
    ...mapState(['siteStore', 'gamification']),
    messages() {
      if (this.gamification.gamification) {
        clearInterval(this.animation)
        this.setCurrentPosition(0)
        return [this.gamification.gamification.title]
      }
      // eslint-disable-next-line camelcase
      const messageByCountry = this.siteStore?.localized_global_messages?.[
        this?.$store?.state?.countryCode
      ]

      let globalMessages =
        messageByCountry ||
        (this.substore.active
          ? this.substore.global_messages
          : // eslint-disable-next-line camelcase
            this.siteStore?.global_messages) ||
        []

      const fullRoutePath = this.$route.fullPath
      let deviceMessages

      if (fullRoutePath?.includes('/android')) {
        deviceMessages = [
          '<b>Frete Grátis</b> <a href="https://www.gocase.com.br/central-de-ajuda/articles/5689025179917-FRETE-GR%C3%81TIS"> a partir de R$99 </a>',
        ]
      }
      if (fullRoutePath?.includes('/ios')) {
        deviceMessages = [
          '<b>Frete Grátis</b> <a href="https://www.gocase.com.br/central-de-ajuda/articles/5689025179917-FRETE-GR%C3%81TIS"> a partir de R$99 </a>',
        ]
      }

      if (deviceMessages) {
        globalMessages = [...globalMessages, ...deviceMessages]
      }

      return globalMessages
    },
    substore() {
      return this.$store.state.substore
    },

    isCartPage() {
      return this.$route.path === '/cart'
    },
  },
  mounted() {
    if (this.$store.state.userInteracted && this.messages.length > 1)
      this.startTopbarAnimation()
  },
  methods: {
    sendTrackOrderClickDesktopToGTM() {
      this.pushDataLayer({
        event: 'genericEvent',
        eventCategory: 'navigation',
        eventAction: 'track_order_click_desktop',
      })
    },
    startTopbarAnimation() {
      const defaultHeight = 33
      let currentPosition = 0
      let toTop = true
      this.animation = setInterval(() => {
        if (toTop) {
          currentPosition -= defaultHeight
        } else {
          currentPosition += defaultHeight
        }

        if (currentPosition === -(this.messages.length - 1) * 33) {
          toTop = false
        }

        if (currentPosition === 0) {
          toTop = true
        }

        this.setCurrentPosition(currentPosition)
      }, 3000)
    },
    setCurrentPosition(currentPosition) {
      const topbarMessages = document.querySelector('.topbar__messages')
      if (topbarMessages)
        topbarMessages.style.transform = `translateY(${currentPosition}px)`
    },
  },
}
</script>

<style lang="scss">
.topbar {
  background-color: $v4_color_primary_lighter;
  color: #626266;
  padding: 0 10px;
  font-size: 12px;
  padding: 6px 0 7px;
  height: 33px;
  overflow: hidden;

  &--gamification {
    height: auto;
  }

  .topbar__container {
    display: flex;
  }

  .topbar__progress {
    width: 100%;
    height: 8px;
  }

  .topbar__messages {
    display: flex;
    flex-direction: column;
    height: 100%;
    transition: -webkit-transform 0.25s ease-in-out;
    transition: transform 0.25s ease-in-out;
    transition: transform 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out;
    flex-grow: 1;

    .topbar__message {
      height: 33px;
      margin: 0;

      &--gamification {
        height: auto;
        margin-bottom: 5px;
      }
    }

    @media (max-width: $v4_desktop) {
      text-align: center;
    }

    b,
    strong {
      font-weight: bold;
    }

    a {
      color: #f087be;
      text-decoration: underline;
      font-weight: bold;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .topbar__nav {
    display: none;
  }

  .topbar__nav-link {
    color: $v4_color_blue;
    font-weight: bold;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &--blackfriday {
    background-color: black;
    color: white;

    .topbar__nav-link {
      color: white;
      text-decoration: underline;
    }
  }
}

// @media (max-width: $v4_desktop) {
//   .topbar--cart {
//     display: none;
//   }
// }

.topbar--cart {
  display: none;
}

@media (min-width: $screen_large) {
  .topbar {
    .topbar__nav {
      display: flex;
      font-size: 14px;

      > li {
        position: relative;
        padding-left: 22px;
        margin-left: 10px;

        &:first-child:before {
          display: none;
          padding-left: 0;
        }

        &:before {
          content: '⋮';
          position: absolute;
          left: 0;
          top: -1px;
          color: #fbd4da;
          font-size: 14px;
          font-weight: bold;
        }
      }
    }
  }
}
</style>
