export const state = () => ({
  isQuantityUpdated: false,
  isCouponAdded: false,
  isCouponRemoved: false,
})

export const mutations = {
  setQuantityUpdated(state, status) {
    state.isQuantityUpdated = status
  },
  setCouponAdded(state, status) {
    state.isCouponAdded = status
  },
  setCouponRemoved(state, status) {
    state.isCouponRemoved = status
  },
  resetShippingFlags(state) {
    state.isQuantityUpdated = false
    state.isCouponAdded = false
    state.isCouponRemoved = false
  },
}

export const actions = {
  handleQuantityUpdated({ commit }, status) {
    commit('setQuantityUpdated', status)
  },
  handleCouponAproved({ commit }, status) {
    commit('setCouponAdded', status)
  },
  handleCouponRemoved({ commit }, status) {
    commit('setCouponRemoved', status)
  },
  resetShippingFlags({ commit }) {
    commit('resetShippingFlags')
  },
}

export const getters = {
  isQuantityUpdated: (state) => state.isQuantityUpdated,
  isCouponAdded: (state) => state.isCouponAdded,
  isCouponRemoved: (state) => state.isCouponRemoved,
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
