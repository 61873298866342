import mergeRight from 'ramda/src/mergeRight'
import isPresent from '../utils/isPresent'
import toParam from '../utils/toQueryString'
import debounce from '../utils/debounce'
import { NO_IMAGE_URL, PREVIEW_HOST } from '../config'
import isBlank from '~/utils/isBlank'
import URLqueryStringToObject from '~/commun/utils/URLqueryStringToObject'
import withoutBlankValues from '~/commun/utils/withoutBlankValues'
import copyObject from '~/commun/utils/copyObject'

export const state = () => ({
  product: null,
  sku: null,
  material: null,
  customValues: {},
  engineInfo: null,
  withoutCustomization: false,
  currentPrice: null,
  oldPrice: null,
  currentPromotion: null,
  restartingEngine: false,
  engineLoaded: false,
  productStock: null,
})

export const mutations = {
  SET_ENGINE_LOADED(state, bool) {
    state.engineLoaded = bool
  },
  SET_CURRENT_PRICE(state, price) {
    state.currentPrice = price
  },
  SET_CURRENT_PROMOTION(state, promotion) {
    state.currentPromotion = promotion
  },
  SET_OLD_PRICE(state, price) {
    state.oldPrice = price
  },
  SET_WITHOUT_CUSTOMIZATION(state, boolean) {
    state.withoutCustomization = boolean
  },
  SET_PRODUCT(state, payload) {
    state.product = payload
  },
  SET_PRODUCT_STOCK(state, payload) {
    state.productStock = payload
  },
  SET_SKU(state, payload) {
    state.sku = payload
  },
  SET_MATERIAL(state, payload) {
    state.material = payload
  },
  UPDATE_CUSTOM_VALUES(state, payload) {
    state.customValues = { ...state.customValues, ...payload }
  },
  SET_ENGINE_INFO(state, engineInfo) {
    try {
      const info = copyObject(engineInfo)
      if (info?.customization?.preview) delete info.customization.preview
      state.engineInfo = JSON.stringify(info)
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err)
    }
  },
  SET_RESTARTING_ENGINE(state, bool) {
    state.restartingEngine = bool
  },
}

export const actions = {
  setEngineLoaded({ commit }, bool) {
    commit('SET_ENGINE_LOADED', bool)
  },
  setRestartingEngine({ commit, rootState }, bool) {
    const firstCustom = rootState?.product?.product?.customizations?.[0]
    if (
      firstCustom &&
      !firstCustom?.engine.includes('customizr') &&
      !firstCustom?.engine.includes('portinari') &&
      !firstCustom?.engine.includes('pikaso')
    ) {
      commit('SET_RESTARTING_ENGINE', bool)
    }
  },
  resetProductPrice({ commit }) {
    commit('SET_CURRENT_PRICE', null)
    commit('SET_OLD_PRICE', null)
  },
  setCurrentPrice({ commit }, price) {
    commit('SET_CURRENT_PRICE', price)
  },
  setCurrentPromotion({ commit }, promotion) {
    commit('SET_CURRENT_PROMOTION', promotion)
  },
  setOldPrice({ commit }, price) {
    commit('SET_OLD_PRICE', price)
  },
  setWithoutCustomization({ commit }, boolean) {
    commit('SET_WITHOUT_CUSTOMIZATION', boolean)
  },
  setProduct({ commit }, product) {
    commit('SET_PRODUCT', product)
  },
  setProductStock({ commit }, product) {
    commit('SET_PRODUCT_STOCK', product)
  },
  async fetchProduct(
    { commit, rootState: { storeCode: store, currentClickedProductId } },
    // eslint-disable-next-line camelcase
    { slug, query: { current_store_id, show_all }, caseTypeIds }
  ) {
    const objectParams = {
      ...this.getters.storeInfo,
      current_store_id,
      show_all,
      product_id: currentClickedProductId,
    }

    if (this.state.skipStoreValidation) {
      objectParams.inpr = true
    }

    if (caseTypeIds && caseTypeIds.length) {
      objectParams.case_type_ids = caseTypeIds
    }

    const params = toParam(objectParams, {
      removeBlankKeys: true,
    })

    let product = null
    let productStock = null

    try {
      ;[product, productStock] = await Promise.all([
        this.$axios.$get(`/products/${slug}?${params}`),
        this.$axios.$get(`/products/${slug}/stock?${params}`),
      ])
    } catch (err) {
      // eslint-disable-next-line no-console
      await this.app.context.error({
        statusCode: 404,
        message: 'Product not found',
      })
    }

    if (isPresent(product)) {
      commit('SET_PRODUCT', product)
      commit('SET_PRODUCT_STOCK', productStock)
      return { product, productStock }
    } else {
      return null
    }
  },
  /** Use debounce to be more performatic */
  UPDATE_CUSTOM_VALUES: debounce(
    ({ commit }, customValues) => commit('UPDATE_CUSTOM_VALUES', customValues),
    250
  ),
}

export const getters = {
  galleryItems(
    { product, material, sku, withoutCustomization },
    { isEngine, customValues, hasCustoms, previewUrl, isKit },
    { isUsStore }
  ) {
    const images = product?.images ?? []
    if (
      // eslint-disable-next-line camelcase
      product?.product_type_code === 'skin' ||
      // eslint-disable-next-line camelcase
      !product?.use_govinci_for_image
    )
      return images

    let extraAttrs = {}

    // Display fallback img when product imgs is empty
    if (images.length === 0) {
      images.push({ url: NO_IMAGE_URL })
    }

    // Add engine attr if product contain engine customs
    if (isEngine) {
      extraAttrs.engine = true
      // Show dynamic img for engine preview
      if (previewUrl) extraAttrs.url = previewUrl
      // Inject material to know when its time to mount engine
      if (material || product?.material)
        extraAttrs.material = material || product?.material
      // Else add govinci attrs if product has any custom
    } else if (hasCustoms) {
      extraAttrs = { ...extraAttrs, sku, material }
      if (!withoutCustomization) extraAttrs = { ...extraAttrs, customValues }
    }

    if (isKit) extraAttrs.isKit = true

    const imagesClone = JSON.parse(JSON.stringify(images))

    if (isUsStore) {
      imagesClone.forEach((ic) => {
        if (ic.url?.match('newecodeluxegocustomized')) {
          ic.url = ic.url.replace(
            'newecodeluxegocustomized',
            'caseimpactslimstandard'
          )
        }

        if (ic.url?.match('standardprinteers')) {
          ic.url = ic.url.replace('standardprinteers', 'caseimpactslimstandard')
        }
      })
    }

    const [mainImg, ...restImgs] = imagesClone

    return [mergeRight(mainImg, extraAttrs), ...restImgs]
  },
  hasCustoms({ product }) {
    return (
      // eslint-disable-next-line camelcase
      product?.customizations?.length > 0 || product?.default_case_device_id
    )
  },
  isEngine({ product }) {
    return product?.customizations?.some(
      (c) => c.customization_type === 'engine'
    )
  },
  isPrisma({ product }) {
    return product?.customizations?.some((c) => c?.engine?.match('prisma'))
  },
  customValues({ product, customValues }) {
    const defaultValues = URLqueryStringToObject(
      // eslint-disable-next-line camelcase
      product?.default_customization_params
    )

    return mergeRight(defaultValues, withoutBlankValues(customValues))
  },
  previewUrl({ sku, material, customValues }) {
    if (isBlank(sku) || isBlank(material)) {
      return null
    }

    const urlParams = toParam(customValues, {
      removeBlankKeys: true,
    }).toString()

    return `${PREVIEW_HOST}/${sku}/${material}/mockup?${urlParams}`
  },
  isKit({ product }) {
    // eslint-disable-next-line camelcase
    return product?.product_type_code === 'kit'
  },
}
