export const state = () => ({
  socialUserInfo: {},
})

export const mutations = {
  SET_SOCIAL_USER_INFO(state, socialUserInfo) {
    state.socialUserInfo = socialUserInfo
  },
  RESET_SOCIAL_USER_INFO(state) {
    state.socialUserInfo = {}
  },
}

export const actions = {
  updateSocialUserInfo({ commit }, socialUserInfo) {
    commit('SET_SOCIAL_USER_INFO', socialUserInfo)
  },
  resetSocialUserInfo({ commit }) {
    commit('RESET_SOCIAL_USER_INFO')
  },
}
