/**
 * Returns given Object with keys that value is blank
 *
 * @param {Object} obj object to remove blank keys values
 * @returns {Object} new obj without blank values
 */

import isBlank from '../../utils/isBlank'

export default function withoutBlankValues(obj) {
  if (isBlank(obj) || typeof obj !== 'object') {
    return {}
  }

  return Object.keys(obj).reduce((newObj, key) => {
    if (!isBlank(obj[key])) {
      newObj[key] = obj[key]
    }

    return newObj
  }, {})
}
