<template>
  <img
    :class="['sprite', tag]"
    src="~assets/images/pixel.gif"
    alt="Pixel"
    width="100px"
    height="100px"
  />
</template>
<script>
export default {
  props: {
    tag: {
      type: String,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~/assets/css/icons-regular.scss';
</style>
