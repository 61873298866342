<template>
  <div class="v-scrollable v-scrollable--default" :style="{ left }">
    <div v-if="label" class="v-scrollable__header">{{ label }}</div>

    <div
      v-for="(item, idx) in items"
      :key="idx"
      class="v-scrollable__item v-scrollable__item--noimage"
      @click="$emit('change', item)"
    >
      <slot v-if="!showText" :item="item"></slot>
      <span v-else>{{ showText(item) }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VScrollable',
  props: {
    label: {
      type: String,
      default: null,
    },
    items: {
      type: Array,
      default: null,
    },
    showText: {
      type: Function,
      default: null,
    },
    left: {
      type: String,
      default: '0px',
    },
  },
}
</script>

<style lang="scss" scoped>
$suggestion_item_height: 45px;

.v-scrollable {
  text-align: left;
  cursor: default;
  border-top-width: 1px;
  background: #fff;
  border-top: 1px solid #f1f4f8;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 40px;

  /* core styles should not be changed */
  position: absolute;
  z-index: 9999;
  max-height: 354px;
  overflow: hidden;
  overflow-y: auto;
  box-sizing: border-box;
  top: 0;
  left: 0;

  &.v-scrollable--default {
    width: calc(100% - 50px);
  }
}

.v-scrollable__item {
  position: relative;
  display: block;
  min-height: $suggestion_item_height + 16;
  border: 1px solid #f1f4f8;
  padding: 8px;
  color: #777;
  text-decoration: none;
  box-sizing: border-box;
  cursor: pointer;

  &:hover {
    background: $color_primary_light;
  }

  &.v-scrollable__item--noimage {
    min-height: 0;
    padding-left: 12px;
    padding-right: 12px;
  }
}
.v-scrollable__image {
  position: absolute;
  width: $suggestion_item_height;
  height: $suggestion_item_height;
  background: #eee;
  top: 8px;
  left: 8px;
}
.v-scrollable__text {
  margin-left: $suggestion_item_height + 10;
  margin-top: 13px;
}

.v-scrollable__header {
  background-color: #fafafa;
  font-size: 11px;
  text-transform: uppercase;
  padding: 8px 12px;
  color: #999;
}

@media (max-width: $screen_large) {
  .v-scrollable.v-scrollable--default {
    top: 45px;
    width: 100%;
    left: 0;
    right: 0;
    margin-top: 0px;
  }
}

@media (min-width: $screen_large) {
  html .v-scrollable {
    margin-top: 4px;
    top: 100%;
    padding: 10px 20px 20px;
    max-height: 374px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;

    &.v-scrollable--default {
      width: 300px;
    }

    .v-scrollable__header {
      font-size: 13px;
      font-weight: bold;
      color: $v4_color_text;
      background-color: white;
      text-transform: none;
    }

    .v-scrollable__item {
      border: 0;
      padding-top: 8px;
      padding-bottom: 8px;
      border-radius: 4px;
      color: $v4_color_text;

      &:hover {
        background-color: #ffede9;
        color: $v4_color_primary;
      }
    }
  }
}
</style>
