import Vue from 'vue'
import OverlaidLoading from '~/components/loadings/overlaid'

const OverlaidLoadingComponent = Vue.extend(OverlaidLoading)

/**
 * Globay display a message at the top of the page
 *
 * @param {String} message message to display
 * @param {Object} options set OverlaidLoading component props
 *
 * @returns component instance
 */

export function displayOverlaidLoading(toggle) {
  if (toggle === 'close') {
    document.querySelector('#overlaid-loading')?.remove()
    document.body.classList.remove('modal--opened')
    return
  }

  const containerId = 'overlaid-loading'
  let containerEl = document.getElementById(containerId)

  if (!containerEl) {
    containerEl = document.createElement('div')
    containerEl.setAttribute('id', containerId)
    document.body.appendChild(containerEl)
    document.body.classList.add('modal--opened')
  }

  return new OverlaidLoadingComponent({}).$mount(containerEl)
}

Vue.prototype.$displayOverlaidLoading = displayOverlaidLoading
