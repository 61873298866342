export const state = () => ({
  drawerOpened: false,
})

export const mutations = {
  TOGGLE_DRAWER(state, boolean) {
    state.drawerOpened = boolean
  },
}

export const actions = {
  openDrawer({ commit }) {
    commit('TOGGLE_DRAWER', true)
  },
  closeDrawer({ commit }) {
    commit('TOGGLE_DRAWER', false)
  },
}

export const getters = {
  drawerIsOpened({ drawerOpened }) {
    return drawerOpened
  },
}
