<template>
  <div id="cookie-consent-banner" class="cookie-consent-banner">
    <h3>{{ $t('cookie_settings') }}</h3>
    <p>
      {{ $t('cookie_message') }}
      <a target="_blank" href="https://www.gocase.com/privacy-policy">{{
        $t('privacy_policy')
      }}</a
      >.
    </p>
    <button
      id="btn-accept-all"
      :class="[
        'cookie-consent-button',
        'btn-success',
        {
          'btn-success--not-european': !isEuCountry,
        },
      ]"
    >
      {{ $t('accept_all') }}
    </button>
    <button
      v-if="isEuCountry"
      id="btn-accept-some"
      class="cookie-consent-button btn-outline"
    >
      {{ $t('accept_selection') }}
    </button>
    <button
      v-if="!$isBrStore"
      id="btn-reject-all"
      class="cookie-consent-button btn-grayscale"
    >
      {{ $t('reject_all') }}
    </button>
    <div v-if="isEuCountry">
      <button class="cookie-consent-btn" @click="seeOptions = true">
        {{ $t('see_selected_options') }}
      </button>
    </div>
    <transition name="fade">
      <div v-show="seeOptions" class="cookie-consent-options">
        <label
          ><input
            id="consent-necessary"
            type="checkbox"
            value="Necessary"
            checked
            disabled
          />Necessary</label
        >
        <label
          ><input
            id="consent-analytics"
            type="checkbox"
            value="Analytics"
            checked
          />Analytics</label
        >
        <label
          ><input
            id="consent-preferences"
            type="checkbox"
            value="Preferences"
            checked
          />Preferences</label
        >
        <label
          ><input
            id="consent-marketing"
            type="checkbox"
            value="Marketing"
          />Marketing</label
        >
      </div>
    </transition>
  </div>
</template>

<script>
import Cookie from '~/utils/Cookie'
import { EUROPEAN_CONTRIES } from '~/config'

export default {
  name: 'CookieModal',
  data() {
    return {
      seeOptions: false,
    }
  },
  computed: {
    isEuCountry() {
      return EUROPEAN_CONTRIES.includes(this.$store.state.countryCode)
    },
  },
  mounted() {
    const acceptAutomatically = () => {
      document.getElementById('btn-accept-all').click()

      window.removeEventListener(acceptAutomatically)
    }

    window.addEventListener('scroll', acceptAutomatically)

    if (!this.isEuCountry) {
      this.setConsentCookie(true, 'not european country')
    }

    function hideBanner() {
      document.getElementById('cookie-consent-banner').style.bottom = '-100%'
    }

    if (localStorage.getItem('consentMode') === null) {
      document
        .getElementById('btn-accept-all')
        ?.addEventListener('click', () => {
          this.setConsent({
            necessary: true,
            analytics: true,
            preferences: true,
            marketing: true,
          })
          hideBanner()
        })
      document
        .getElementById('btn-accept-some')
        ?.addEventListener('click', () => {
          this.setConsent({
            necessary: true,
            analytics: document.getElementById('consent-analytics').checked,
            preferences: document.getElementById('consent-preferences').checked,
            marketing: document.getElementById('consent-marketing').checked,
          })
          hideBanner()
        })
      document
        .getElementById('btn-reject-all')
        ?.addEventListener('click', () => {
          this.setConsent({
            necessary: false,
            analytics: false,
            preferences: false,
            marketing: false,
          })
          hideBanner()
        })
      document.getElementById('cookie-consent-banner').style.bottom = '0'
    }
  },
  methods: {
    setConsent(consent) {
      const consentMode = {
        functionality_storage: consent.necessary ? 'granted' : 'denied',
        security_storage: consent.necessary ? 'granted' : 'denied',
        ad_storage: consent.marketing ? 'granted' : 'denied',
        analytics_storage: consent.analytics ? 'granted' : 'denied',
        personalization: consent.preferences ? 'granted' : 'denied',
      }

      setTimeout(() => {
        window.gtag('consent', 'update', consent)
      }, 1000)

      localStorage.setItem('consentMode', JSON.stringify(consentMode))

      if (this.isEuCountry) {
        this.setConsentCookie(consent.analytics, 'end-user response')
      }
    },
    setConsentCookie(consent, reason) {
      const cookieData = {
        v4_consent: consent ? 'consented' : 'denied',
        domain: '.gocase.com',
        path: '/',
        SameSite: 'None',
        Secure: true,
      }
      // eslint-disable-next-line no-console
      console.log(
        '[owncookie] User CONSENTED cookies. Reason:',
        reason,
        `| At time: ${performance.now()}`
      )

      const myDate = new Date()
      myDate.setMonth(myDate.getMonth() + 12)
      Cookie.Client(document.cookie).set({ ...cookieData, expires: myDate })
      // eslint-disable-next-line no-console
      console.log('[owncookie] V4 cookie created.')
    },
  },
}
</script>

<style lang="scss" scoped>
.cookie-consent-banner {
  position: fixed;
  bottom: -100%;
  left: 0;
  right: 0;
  background-color: #f8f9fa;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  color: black;
  padding: 15px;
  font-size: 12px;
  text-align: center;
  z-index: 1000;
  transition: bottom 1s ease-in-out;
}

.cookie-consent-banner h3 {
  margin: 0;
}

.cookie-consent-button {
  border: none;
  padding: 8px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 20px;
  transition: box-shadow 0.3s ease;
}

.cookie-consent-button:hover {
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.05);
}

.cookie-consent-button:active {
  opacity: 0.5;
}

.cookie-consent-button.btn-success {
  color: white;
  background: #34a853;
}

.btn-success--not-european {
  background-color: #333 !important;
}

.cookie-consent-button.btn-grayscale {
  background-color: #eeeeee;
  color: black;
  opacity: 0.7;
}

.cookie-consent-button.btn-outline {
  background-color: #e6f4ea;
  color: #34a853;
}

.cookie-consent-options {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 10px;
  margin-top: 10px;
}

.cookie-consent-options label {
  margin: 0 10px;
  font-size: 14px;
}

.cookie-consent-options input {
  margin-right: 5px;
}

.cookie-consent-btn {
  background: none;
  border: none;
  color: #666;
  cursor: pointer;
  font-size: 13px;
  margin-top: 10px;
  margin-bottom: 10px;
  text-decoration: underline;
}
</style>
