<template>
  <header
    :class="[
      'header',
      {
        'header--static': isMobileFixedMockup,
        'header--blackfriday': $store.state.isBlackFriday,
        'header--christmas': $store.state.isChristmas,
      },
    ]"
  >
    <div class="container header__container">
      <div class="header__nav">
        <a
          href="#"
          rel="nofollow"
          class="header__item header__item--hamburger header__item--mobile"
          aria-label="Menu"
          @click.prevent
        >
          <header-icon-hamburger />
        </a>

        <nuxt-link
          to="/"
          :class="[
            'header__item',
            'header__item--logo',
            {
              'header__item--logo-sudden':
                $isBrStore && $store.state.isGocaseBirth,
            },
          ]"
          aria-label="Home"
        >
          <header-logo />
        </nuxt-link>

        <div
          class="header__item header__item--desktop header__item--menu"
          :class="{ 'header__item--blackfriday': $store.state.isBlackFriday }"
        >
          <header-menu v-if="showMenu" class="desktop-only" />
        </div>

        <div v-if="!noContent" class="header__item header__item--searchfield">
          <header-search
            :active="mobileInputSearchOpened"
            @close="closeMobileInputSearch"
          />
        </div>

        <div v-if="noContent" class="header__item-100-seal">
          <sprite-tag tag="selo_100-seguro" />
        </div>

        <template v-if="!noContent">
          <a
            href="#"
            rel="nofollow"
            class="header__item header__item--icon"
            aria-label="Search"
            @click.prevent="openMobileInputSearch"
          >
            <icon-search />
          </a>

          <nuxt-link
            v-if="isLogged"
            to="/wishlist"
            class="header__item header__item--icon"
            aria-label="Wishlist"
          >
            <icon-heart />
          </nuxt-link>

          <nuxt-link
            to="/cart"
            class="header__item header__item--icon"
            aria-label="Cart"
          >
            <header-cart :qty="cartQty" />
          </nuxt-link>

          <user-widget class="header__item header__item--icon" />
        </template>
      </div>
    </div>
  </header>
</template>

<script>
import HeaderIconHamburger from './header-icon-hamburguer/HeaderIconHamburger.vue'
import HeaderLogo from './header-logo/HeaderLogo.vue'
import HeaderSearch from './header-search/HeaderSearch.vue'
import HeaderCart from './header-cart/HeaderCart.vue'
import HeaderMenu from './header-menu/HeaderMenu.vue'
import IconSearch from '~/components/icons/magnifier/Magnifier'
import IconHeart from '~/components/icons/heart/Heart'
import UserWidget from '~/components/header/user-widget/UserWidget.vue'
import isPresent from '~/utils/isPresent'
import SpriteTag from '~/components/sprite-tag/SpriteTag.vue'

export default {
  name: 'Header',
  components: {
    HeaderLogo,
    HeaderIconHamburger,
    HeaderMenu,
    HeaderSearch,
    HeaderCart,
    IconSearch,
    IconHeart,
    UserWidget,
    SpriteTag,
  },
  props: {
    noContent: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      helpCenterUrl: 'https://www.gocase.com/central-de-ajuda',
      mobileInputSearchOpened: false,
    }
  },
  computed: {
    isMobileFixedMockup() {
      return this.$device.isMobile && this.$route.name === 'slug-p-case_device'
    },
    cartQty() {
      return this.$store.state.cartQty
    },
    isLogged() {
      return this.$store.getters.isLogged
    },
    showMenu() {
      return !this.noContent // && this.$device.isDesktop
    },
  },

  methods: {
    isPresent,
    openMobileInputSearch() {
      this.mobileInputSearchOpened = true
      setTimeout(() => {
        const searchInput = document.querySelector('[name=q]')
        searchInput?.focus()
      }, 50)
    },
    closeMobileInputSearch() {
      this.mobileInputSearchOpened = false
    },
  },
}
</script>

<style lang="scss">
@import '~/assets/css/_utils.scss';
@import '~/assets/css/_icon.scss';

body {
  padding-top: $nav_mobile_height + 1px;
}

.header {
  position: fixed;
  z-index: 10;
  width: 100%;
  box-shadow: 0 1px 0 0 #f1f4f8;
  background-color: white;
  top: 0;

  &--static {
    position: static;
  }

  .header__item-100-seal {
    height: 100%;
    display: flex;
    align-items: center;

    img {
      width: 100px;
      height: 25px;
    }
  }

  .header__container {
    padding: 0;
  }

  a {
    color: #555;
    text-decoration: none;
  }

  .header__nav {
    display: flex;
    height: $nav_mobile_height;
    justify-content: flex-end;
  }

  .header__right {
    position: absolute;
    top: 0;
    right: 5px;
    display: flex;
  }

  .header__item {
    height: $nav_mobile_height;
    padding-right: 15px;

    &.header__item--desktop {
      display: none;
    }

    &.header__item--hamburger {
      width: auto;
      position: absolute;
      left: 0;
      padding-right: 0;
    }
    &.header__item--logo {
      display: block;
      padding-top: 16px;
      position: absolute;
      left: 60px;
    }

    &.header__item--logo-sudden {
      padding-top: 0;
    }
    &.header__item--icon {
      padding-top: 13px;
      text-align: center;

      svg {
        width: 26px;
        fill: #555;
      }
    }
  }
}

.header__item--desktop {
  overflow-x: scroll;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    height: 9px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: $v4_color_orange;
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
}

.header__item--blackfriday {
  &::-webkit-scrollbar-thumb {
    background-color: $black_friday_color_primary !important;
  }
}

@media (min-width: $screen_large) {
  body {
    padding-top: 0;
  }

  .header {
    position: static;

    .header__nav {
      height: 94px;
      justify-content: flex-start;
      align-items: flex-end;
      position: relative;
    }

    .header__container {
      position: relative;
    }
    .header__item {
      width: auto;
      height: auto;
      font-size: 15px;
      padding-right: 0;
      padding-left: 0;
      margin-left: 22px;
      padding-bottom: 27px;

      &.header__item--menu {
        flex-grow: 1;
        padding-bottom: 0;
      }

      &.header__item--desktop {
        display: block;
      }

      &.header__item--mobile {
        z-index: 1;
        display: none;
      }

      &.header__item--logo {
        padding-left: 0;
        position: static;
        margin: 0;
      }

      &.header__item--searchfield {
        padding-left: 0;
        padding-right: 0;
      }

      &.header__item--icon {
        &:hover {
          color: #000;

          svg {
            fill: $v4_color_primary;
          }
        }

        svg {
          width: 28px;
        }
      }
    }

    .header__rewards-link,
    .header__vipsubscription-link {
      display: block;
    }
  }
}

.header {
  &--blackfriday {
    background: black;

    .header__item--logo {
      padding-bottom: 20px;
    }

    @media (max-width: $v4_desktop) {
      .header__item--logo {
        padding: 0 !important;
        left: 50% !important;
        transform: translateX(-50%) !important;
      }
    }

    a {
      color: white;
    }

    svg {
      fill: white !important;
    }
  }

  &--christmas {
    background: #6b0a03;

    .header__item--logo {
      padding-bottom: 20px;
    }

    @media (max-width: $v4_desktop) {
      .header__item--logo {
        padding: 0 !important;
        left: 50% !important;
        transform: translateX(-50%) !important;
      }
    }

    a {
      color: white;
    }

    svg {
      fill: white !important;
    }
  }
}
</style>
