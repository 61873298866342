<template>
  <div :class="['user-widget', { 'user-widget--logged': isLogged }]">
    <nuxt-link :to="userLink" class="user-widget__icon" aria-label="User">
      <user-icon />
    </nuxt-link>

    <ul v-if="isLogged" class="user-widget__submenu">
      <li>
        <nuxt-link to="/account">
          <span>{{ $t('user.greetings') }}</span
          ><br />
          <strong>{{ firstName }}</strong>
        </nuxt-link>
      </li>
      <li>
        <nuxt-link to="/orders"> {{ $t('user.my_orders') }}</nuxt-link>
      </li>
      <li>
        <nuxt-link to="/account/edit">{{ $t('user.my_data') }}</nuxt-link>
      </li>
      <li>
        <nuxt-link to="/addresses">{{ $t('user.my_addresses') }}</nuxt-link>
      </li>
      <li>
        <nuxt-link to="/protocols">{{ $t('user.my_protocols') }}</nuxt-link>
      </li>
      <!-- REWARDS - Comentado também no ReferralWidget e HeaderDrawerContent
      <li v-if="$isBrStore">
        <nuxt-link to="/rewards">{{ $t('user.my_rewards') }}</nuxt-link>
      </li>
      -->
      <li>
        <logout-button />
      </li>
    </ul>
  </div>
</template>
<script>
import UserIcon from '~/components/icons/user/User'
import LogoutButton from '~/components/shared/logout-button/LogoutButton.vue'

export default {
  components: { UserIcon, LogoutButton },
  computed: {
    isLogged() {
      return this.$store.getters.isLogged
    },
    firstName() {
      // eslint-disable-next-line camelcase
      return this.$store.state.user?.first_name
    },
    userLink() {
      return this.isLogged ? '/account' : '/login'
    },
  },
}
</script>
<style lang="scss" scoped>
$desktop_item_height: 50px;

.user-widget {
  display: none;
  position: relative;
  text-align: left;
}
.user-widget__submenu {
  text-align: left;
  position: absolute;
  top: $desktop_item_height;
  z-index: 2;
  right: 0;
  width: 200px;
  margin: 0;
  padding: 0;
  list-style-type: none;
  background: white;
  border: 1px solid $color_gray;
  box-shadow: 1px 2px 1px rgba(0, 0, 0, 0.05);
  transform: translateY(-10px);
  visibility: hidden;
  transition: all 0.15s ease-in-out;
  opacity: 0;
}
.user-widget:hover .user-widget__submenu {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}
.user-widget .user-widget__submenu a {
  border-top: 1px solid $color_gray;
  padding: 10px 15px;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  color: #555;

  &:hover {
    background-color: #fafafa;
    color: #555;
  }
}
@media (min-width: $screen_large) {
  .user-widget .user-widget__icon {
    padding-right: 5px;
  }

  .user-widget,
  .user-widget a {
    display: block;
  }

  .user-widget a .user-widget__name {
    font-size: 11px;
    display: block;
    text-transform: none;
    max-width: 110px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.user-widget--logged {
  .user-widget__icon {
    position: relative;

    &::before,
    &::after {
      background-color: #a9abb3;
      content: '';
      display: block;
      position: absolute;
      width: 1px;
      height: 7px;
      right: 5px;
      top: 10px;
      transition: transform 0.25s ease-in-out;
    }
    &::before {
      transform: translate3d(2px, 0, 0) rotate(45deg);
    }
    &::after {
      transform: translate3d(-2px, 0, 0) rotate(-45deg);
    }

    &:hover {
      &::before,
      &::after {
        background-color: $v4_color_primary;
      }
    }
  }
}
</style>
