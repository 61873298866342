export default function triggerOnUserInteraction(callback) {
  const events = ['scroll', 'click', 'keyup', 'touchstart']

  events.forEach((event) => {
    document.addEventListener(event, onIneraction, { once: true })
  })

  async function onIneraction(e) {
    await callback()

    removeListeners()
  }

  function removeListeners() {
    events.forEach((event) => {
      document.removeEventListener(event, onIneraction)
    })
  }
}
