const SPECIAL_CHARACTERS = new RegExp(/[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/)

function event(name) {
  const evt = document.createEvent('Event')
  evt.initEvent(name, true, true)
  return evt
}

export default function (el, binding) {
  el.oninput = function (evt) {
    if (!evt.isTrusted) return // avoid infinite loop
    const mask = binding.value
    const lastChar = evt.data
    const value = el.value
    const valueLength = value.length
    const currentMaskChar = mask[valueLength - 1]
    const nextMaskChar = mask[valueLength]

    if ([null, undefined].includes(lastChar)) {
      const splittedValue = value.split('')

      if (mask[el.selectionEnd] === ' ' && value.length !== el.selectionEnd) {
        splittedValue.splice(el.selectionEnd, 0, ' ')
        el.value = splittedValue.join('')
        return
      }

      let indexes = splittedValue.map((sv, idx) => (sv === ' ' ? idx : null))
      if (
        el.selectionEnd !== valueLength &&
        indexes.find((idx) => el.selectionEnd < idx)
      ) {
        indexes = indexes.filter((idx) => idx !== null)
        indexes.forEach((index) => {
          splittedValue.splice(index, 1)
          splittedValue.splice(index + 1, 0, ' ')
        })
        el.value = splittedValue.join('')
      }

      return
    }

    if (currentMaskChar === '#') {
      el.dispatchEvent(event('input'))
      return
    }

    if (valueLength > mask.length || SPECIAL_CHARACTERS.test(lastChar)) {
      el.value = el.value.slice(0, valueLength - 1)
      el.dispatchEvent(event('input'))
      return
    }

    if (lastChar === ' ' && currentMaskChar !== ' ') {
      el.value = el.value.slice(0, valueLength - 1)
      return
    }

    if (currentMaskChar === ' ') {
      el.value = el.value.slice(0, valueLength - 1)
      el.value = el.value + ' ' + lastChar
    }

    if (isNaN(currentMaskChar)) {
      if (currentMaskChar === 'S') {
        if (isNaN(lastChar)) el.dispatchEvent(event('input'))
        else el.value = el.value.slice(0, valueLength - 1)
      } else {
        el.dispatchEvent(event('input'))
      }
    } else if (!isNaN(currentMaskChar)) {
      if (currentMaskChar === ' ' && nextMaskChar === 'A') {
        el.dispatchEvent(event('input'))
        return
      }
      if (!isNaN(lastChar)) {
        el.dispatchEvent(event('input'))
      } else {
        el.value = el.value.slice(0, valueLength - 1)
      }
    }
  }
}
