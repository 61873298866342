import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { LOCALES } from '~/config'

Vue.use(VueI18n)

const defaultLocale = process.env.NODE_ENV === 'test' ? 'en' : 'pt-BR'

export const i18n = createI18n(defaultLocale)

const loadedLanguages = [defaultLocale] // our default language that is preloaded

function getMessages(locale) {
  const messages = { [locale]: require(`~/locales/${locale}.json`) }
  if (locale !== 'en' && locale !== 'pt-BR')
    messages.en = require('~/locales/en.json')
  return messages
}

function createI18n(locale = defaultLocale) {
  return new VueI18n({
    locale,
    fallbackLocale: 'en',
    messages: getMessages(locale),
    missing: (_, label) => {
      const splitedText = label.split('.')
      const string = splitedText[splitedText.length - 1]
      if (!string[0]) return ''
      return string[0].toUpperCase() + string.slice(1).split('_').join(' ')
    },
  })
}

export function setI18nLanguage(lang) {
  i18n.locale = lang

  if (process.client) {
    document.querySelector('html').setAttribute('lang', lang)
  }

  return lang
}

export function loadLanguageAsync(lang) {
  // If the same language
  if (i18n.locale === lang) {
    return Promise.resolve(setI18nLanguage(lang))
  }

  // If the language was already loaded
  if (loadedLanguages.includes(lang)) {
    return Promise.resolve(setI18nLanguage(lang))
  }
  if (!LOCALES.includes(lang)) {
    return Promise.reject(new Error(`Locale "${lang}" not registred`))
  }
  // If the language hasn't been loaded yet
  return import(
    /* webpackChunkName: "lang-[request]" */ `~/locales/${lang}.json`
  ).then((messages) => {
    i18n.setLocaleMessage(lang, messages.default)
    loadedLanguages.push(lang)
    return setI18nLanguage(lang)
  })
}

export default async ({ app, store }) => {
  const locale = store.state.locale
  await loadLanguageAsync(locale || defaultLocale)
  app.i18n = createI18n(locale)
}
