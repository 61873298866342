<template>
  <nuxt />
</template>

<script>
export default {
  layout: 'empty',
  mounted() {
    this.$store.commit('SET_USER_INTERACTED')
  },
}
</script>
