export const state = () => ({
  popularSearches: [],
})

export const mutations = {
  SET_POPULAR_SEARCHES(state, popularSearches) {
    state.popularSearches = popularSearches
  },
}

export const actions = {
  async fetchPopularSearches({
    commit,
    rootState: { storeCode: store, locale, substore },
  }) {
    let popularSearches = []

    const params = {
      locale,
      store,
      substore: substore.code,
    }

    try {
      popularSearches = await this.$axios.$get('/popular_search', {
        params,
      })

      popularSearches = popularSearches.map((res) => res.term)
    } catch (err) {
      throw new Error(err)
    }

    commit('SET_POPULAR_SEARCHES', popularSearches)
  },
}
