import isBlank from './isBlank'

/**
 * Check if given value is present (not null, undefined, '', {}, [])
 * @param {*} value any value
 *
 * @returns {Boolean} true if value is present
 */

export default function isPresent(value) {
  return !isBlank(value)
}
