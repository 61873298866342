<template>
  <svg :class="cssClass" width="41" height="67" viewBox="0 0 41 67" fill="none">
    <path
      class="logo-animated-path"
      d="M32.6 37.8998L33 37.5998C33.4 37.2998 33.7 36.9998 34.1 36.5998C37.7 32.9998 39.6 28.5998 39.6 23.2998C39.6 20.1998 38.9 17.3998 37.7 14.8998L31.1 18.6998C31.7 20.0998 32 21.5998 32 23.2998C32 26.4998 30.9 29.1998 28.8 31.2998C26.7 33.3998 24 34.4998 20.9 34.4998C17.8 34.4998 15.2 33.3998 13 31.2998C10.9 29.1998 9.8 26.4998 9.8 23.2998C9.8 20.0998 10.9 17.3998 13 15.2998C15.1 13.1998 17.8 12.0998 20.9 12.0998H27.5C28.4 12.1998 31.5 11.9998 34.3 10.1998C34.6 9.99976 34.9 9.69976 35.3 9.39976C36.8 7.99976 38.6 5.39976 38.6 0.999756H31C31 2.39976 30.7 3.39976 30.1 3.89976C29.3 4.69976 27.9 4.69976 27.7 4.69976H20.8C15.6 4.59976 11.1 6.39976 7.5 9.99976C3.8 13.6998 2 18.0998 2 23.2998C2 28.5998 3.8 32.9998 7.5 36.5998C11.1 40.1998 15.6 41.9998 20.8 41.9998C22.1 41.9998 23.4 41.8998 24.7 41.6998C28 42.6998 31.1 45.0998 31.1 49.4998C31.1 54.7998 26.9 58.3998 20.8 58.3998C14.9 58.1998 10.4 54.7998 10 49.9998H2C2.3 59.2998 9.9 65.6998 20.7 65.6998C31.7 65.6998 39.1 59.0998 39.1 49.3998C39.1 44.0998 37 40.3998 32.6 37.8998Z"
      :stroke="color"
      stroke-width="1.25"
    />
  </svg>
</template>

<script>
export default {
  props: {
    cssClass: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: '#f37053',
    },
  },
}
</script>

<style lang="scss">
.logo-animated-path {
  stroke-dasharray: 400;
  stroke-dashoffset: 400;
  animation: dash 1.25s ease-in-out alternate infinite;
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}
</style>
