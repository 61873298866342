import isPresent from '~/utils/isPresent'
import appendUrlParam from '~/utils/appendUrlParam'

const directiveIndex = (directives, name) => {
  return directives.findIndex((item) => item.name === name)
}

const buildDynamicSrcset = (isMobile, el, srcsetType) => {
  let breakpoints = []
  let sizes = null
  if (srcsetType === 'catalogProduct') {
    sizes =
      '(min-width: 1200px) 290px, (min-width: 992px) 240px, (min-width: 768px) 24vw, 50vw'
    breakpoints = isMobile ? ['187', '374'] : ['279', '550']
  } else if (srcsetType === 'shelfProduct') {
    sizes = '(min-width: 992px) 186px, 186px'
    breakpoints = isMobile ? ['186', '372'] : ['186', '372']
  }

  const baseUrl = el.dataset.src || el.src
  const srcset = breakpoints
    .map((breakpoint) => {
      const srcsetUrl = appendUrlParam(baseUrl, { tr: `w-${breakpoint}` })
      return `${srcsetUrl} ${breakpoint}w`
    })
    .join(', ')
  return { srcset, sizes }
}

const buildStaticSourceset = (isMobile, srcsetStructure) => {
  const srcset = srcsetStructure?.srcset || ''
  const sizes = srcsetStructure?.sizes || ''

  return { srcset, sizes }
}

export default function (el, binding, vnode) {
  const { srcsetType, isMobile, srcsetStructure, ignore } = binding.value
  if (ignore) return
  const { directives } = vnode.data
  const lazyloadIndex = directiveIndex(directives, 'lazyload')
  const srcsetIndex = directiveIndex(directives, 'srcset')

  const { srcset, sizes } = srcsetStructure
    ? buildStaticSourceset(isMobile, srcsetStructure)
    : buildDynamicSrcset(isMobile, el, srcsetType)

  if (isPresent(srcset) && isPresent(sizes)) {
    if (lazyloadIndex >= 0 && !directives[lazyloadIndex]?.value?.ignore) {
      if (lazyloadIndex < srcsetIndex)
        throw new Error(
          'srcset directive should be injected before lazyload directive'
        )
      el.dataset.srcset = srcset
      el.dataset.sizes = sizes
    } else {
      el.srcset = srcset
      el.sizes = sizes
    }
  }
}
