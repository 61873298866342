import sanitizePath from '~/utils/sanitize-path'

export default ({ app, redirect }) => {
  app.router.beforeEach((to, _from, next) => {
    const newPath = sanitizePath(to)

    if (newPath) redirect(newPath) && next(false)
    else next()
  })
}
