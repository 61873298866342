var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{class:[
    _vm.item.css_class,
    _vm.item.name_slug,
    { 'nav-item--blackfriday': _vm.$store.state.isBlackFriday } ]},[_c('a',{class:[{ sale: _vm.isLeftFeatured }],attrs:{"href":_vm.adaptedUrl()},on:{"click":function($event){$event.preventDefault();return _vm.pointerEventsNone.apply(null, arguments)}}},[(_vm.featured)?_c('featured-nav-item',{attrs:{"item":_vm.item,"is-left-featured":_vm.isLeftFeatured}}):_c('span',[_vm._v(_vm._s(_vm.name()))]),_vm._v(" "),(_vm.hasChild() && _vm.level <= 1)?_c('icon-chevron-down',{staticClass:"main-menu__arrow main-menu__arrow--top"}):_vm._e(),_vm._v(" "),(_vm.hasChild() && _vm.level > 1)?_c('icon-chevron-right',{staticClass:"main-menu__arrow"}):_vm._e()],1),_vm._v(" "),_c('ul',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasChild()),expression:"hasChild()"}],class:[
      ("main-menu__level-" + _vm.level),
      {
        'main-menu__level--blackfriday': _vm.$store.state.isBlackFriday,
        'main-menu__level--christmas': _vm.$store.state.isChristmas,
      } ]},_vm._l((_vm.childrenItems()),function(childItem,idx){return _c('header-menu-content-item',{key:("" + _vm.level + idx),attrs:{"item":childItem,"level":_vm.nextLevel()}})}),1)])}
var staticRenderFns = []

export { render, staticRenderFns }